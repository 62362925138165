import React, { Component } from "react";
import {

  Select,
  MuiThemeProvider,
  MenuItem
} from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles'
import { numberCheck } from "../../Utilities/SharedFunctions";
import { CircularProgress } from "@material-ui/core";
//import Successful from "./Successful";
import { post_request, getRequest } from "../../Services/FetchFunctions";
import CustomSnackbar from "../SharedComponents/CustomSnackbar";


const MenuProps = {
  style: {
    marginTop: "15px"
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  }
};

const customTopUp = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  overrides: {
    MuiSelect: {
      root: {
        fontFamily: "inherit"
      },
      select: {
        color: "rgba(37, 37, 37, 0.7)",
        "&:focus": {
          backgroundColor: "none"
        }
      },
      icon: {
        position: "relative",
        display: 'none'
      },
      nativeInput: {
        width: "auto",
        display: "none !important"
      }
    },
    MuiMenuItem: {
      root: {
        color: "rgba(37, 37, 37, 0.7)",
        fontFamily: "inherit",
        fontSize: "14px",
        height: "40px",
        width: "100%",
        "&:hover": {
          backgroundColor: "#f4f7ff !important"
        },
        "&$selected": {
          backgroundColor: "#f4f7ff"
        }
      }
    },
    MuiList: {
      padding: {
        paddingTop: "0px",
        paddingBottom: "0px"
      }
    },
    MuiInput: {
      root: {
        position: "inherit",
        height: "40px"
      },
      underline: {
        "&&&:before": {
          borderBottom: "none"
        },
        "&&:after": {
          borderBottom: "none"
        }
      }
    },
    MuiInputBase: {
      root: {
        fontFamily: "inherit",
        fontSize: "14px",
        marginTop: "10px",
        marginBottom: "20px",
        height: "40px",
        border: "1px solid rgba(37, 43, 51, 0.15)",
        borderRadius: "4px",
        "&:hover": {
          border: "1px solid #f58730",
          boxShadow: "0 7px 9px -3px rgba(70, 70, 70, 0.06)"
        },
        "&:focus": {
          border: "1px solid #f58730",
          boxShadow: "0 7px 9px -3px rgba(70, 70, 70, 0.06)"
        }
      },
      input: {
        padding: "0px",
        border: "none !important",
        boxShadow: "none !important",
        margin: "0px !important"
      }
    }
  }
});

class CashOutMpesa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 0,
      sourceAccount: "",
      phoneNumber: "",
      amount: "",
      selectedCurrency: "KES",
      availableCurrencies: ["KES", "USD", "GBP", "EUR"],
      verificationCode: ["", "", "", "", ""],
      isLoading: false,
      responseStatus: "",
      snackbaropen: false,
      snackbarmsg: "",
      snackbartxt: "",
      investmentAccount: [],
      transMessage: "You have managed to do this awesomely",
      paymentRef: "HIOD87689JO",
      totalAmount: '100',
      charges: '40.00',
      transDescription: '',
      validEmail: false,
    };

    this.handleFormInputNumber = this.handleFormInputNumber.bind(this);
    this.handleFormInput = this.handleFormInput.bind(this);
    this.handleView = this.handleView.bind(this);

    this.handleVerificationInput = this.handleVerificationInput.bind(this);
  }

  handleView(view) {
    this.setState({ view: view });
  }
  componentDidMount() {
    this.getAccounts();
    this.setState({ phoneNumber: this.props.phone })
    //console.log(this.props.phone)
  }
  handleFormInputNumber(event) {
    if (event.target.value.length > 0) {
      if (numberCheck(event.target.value)) {
        this.setState({ [event.target.id]: event.target.value });
      }
    } else {
      this.setState({ [event.target.id]: "" });
    }
  }
  getAccounts() {
    const url = "client/account/get-accounts";
    getRequest(url)
      .then((resp) => {
        var accounts = resp.data.List;
        var filteredAccount = accounts.filter(
          (account) => account.AccountType === "INVESTMENT"
        );
        this.setState({
          investmentAccount: filteredAccount
        });
      })
      .catch((err) => {
        console.log(err);
      });

  }

  handleFormInput(event) {
    if (event.target.id) {
      this.setState({ [event.target.id]: event.target.value });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  handleSubmit() {
    this.setState({ isLoading: true });
    var accountNo = this.state.investmentAccount[0];
    // console.log(accountNo)
    var payload = {
      Type: "MPESA-B2C",
      AccountNumber: accountNo.AccountNumber,
      Account: this.state.phoneNumber,
      Amount: this.state.amount,
      Description: this.state.transDescription,
      Currency: "KES",
    };
    //console.log(payload);
    var url = "client/account/cash-out";
    post_request(url, payload)
      .then((resp) => {

        this.setState({
          isLoading: false,
          transMessage: resp.data.Message,
          paymentRef: resp.data.PaymentRef,
          totalAmount: resp.data.TotalAmount,
          charges: resp.data.Charges,
          view: 1,
        });

      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          snackbaropen: true,
          snackbarmsg: "Could not process payment",
          responseStatus: "failed",
          snackbartxt: err.response.data.Message,
        });
      });
  }
  handleVerificationInput(event) {
    var index = event.target.id;
    const newArray = Array.from(this.state.verificationCode);
    const nextSibling = document.querySelector(
      `input[name=ssn-${parseFloat(index, 10) + 1}]`
    );

    if (numberCheck(event.target.value)) {
      if (event.target.value.length > 1) {
        for (var i = 0; i < 5; i++) {
          newArray[i] = event.target.value.charAt(i);
        }
        document.querySelector(`input[name=ssn-4]`).focus();
      } else {
        newArray[index] = event.target.value;
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
      this.setState({ verificationCode: newArray });
    } else {
      newArray[index] = "";
      this.setState({ verificationCode: newArray });
    }
  }
  handleValidation() {
    var url = "client/account/cash-out/validate";
    var verificationCode = this.state.verificationCode
      .toString()
      .replace(/,/g, "");
    var payload = {
      code: verificationCode,
    };
    post_request(url, payload)
      .then((resp) => {
        this.setState({
          isLoading: false,
          snackbaropen: true,
          snackbarmsg: "Transaction successful",
          responseStatus: "success",
          snackbartxt: resp.data.Message,
        });
        setTimeout(function () {
          window.location.reload();
        }, 2500);
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          snackbaropen: true,
          snackbarmsg: "Could not process payment",
          responseStatus: "failed",
          snackbartxt: err.response.data.Message,
        });
      });
  }
  handleKeyPress(e) {
    if (e.target.id === "4") {
      if (e.key === "Enter") {
        this.handleVerification();
      }
    } else {
      if (e.key === "Enter") {
        this.handleSubmit();
      }
    }
  }
  snackbarClose = event => {
    this.setState({ snackbaropen: false });
  };

  render() {
    var CurrencyFormat = require("react-currency-format");
    var account = this.state.investmentAccount[0];
    if (account !== undefined) {
      var curr = account.AccountCurrency;
      var balance = account.AccountBalance;
    }
    return (
      <div>
        <CustomSnackbar
          hideAlert={this.snackbarClose}
          showSnack={this.state.snackbaropen}
          hideSnack={this.snackbarClose}
          response={this.state.responseStatus}
          title={this.state.snackbarmsg}
          messagetxt={this.state.snackbartxt}
        />
        <div className="ButtonPadding">
          <button
            className="BackButton"
            onClick={() => {
              this.state.view === 0
                ? this.props.handleView(0)
                : this.handleView(0);
            }}
          >
            <img src="/icon/back.svg" alt="back" />
            <span>Back</span>
          </button>
        </div>
        {this.state.view === 0 && (
          <div className="ContentSpace">
            <div style={{ width: '450px', padding: '20px', backgroundColor: 'white' }} className="PersonalInfo PaymentsForm">
              <div
                className="ContentHeader"
                style={{ textAlign: "center", marginBottom: "35px" }}
              >
                <span
                  className="MainContentHeader Bold"
                  style={{ display: "block", marginBottom: "10px" }}
                >
                  Cash-out to MPESA
                </span>
                <span className="MainContentSubheader">
                  Please note you can only cash out to the Mpesa number you registered with{" "}
                </span>
              </div>
              <div className="SendMoneyBalance">
                <img
                  style={{ marginRight: "5px" }}
                  src="/icon/entypo_wallet.svg"
                  alt="wallet"
                />
                <span className="MainContentSubheader">Investment account balance</span>
                <span className="BlueBold">{curr} <CurrencyFormat
                  value={parseFloat(balance).toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                /> </span>
              </div>
              <MuiThemeProvider theme={customTopUp}>
                <div className="ProfileForm">

                  <label>Amount</label>
                  <div className="SignUpFormsTwoInOne">
                    <div className="SignUpFormsTwoInOneSmall">
                      <Select
                        className="PaymentSelect"
                        style={{
                          fontFamily: "inherit",
                          fontSize: "14px",
                          marginTop: "0px"
                        }}
                        name="selectedCurrency"
                        value={this.state.selectedCurrency}
                        onChange={event => this.handleFormInput(event)}
                        disableUnderline={true}
                        MenuProps={MenuProps}
                        disabled
                      >
                        {this.state.availableCurrencies.map((code, index) => (
                          <MenuItem value={code} key={index}>
                            {code}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <input
                      id="amount"
                      type="text"
                      autoComplete="off"
                      maxLength="9"
                      value={this.state.amount}
                      onChange={this.handleFormInputNumber}
                      required
                    />
                  </div>
                  <label>Enter your MPESA phone number</label>
                  <span
                    style={{ display: "block", marginBottom: "10px" }}
                    className="MainContentSubheader"
                  >
                    You’ll will receive a code on your phone to validate this
                    transaction{" "}
                  </span>
                  <input
                    id="phoneNumber"
                    type="text"
                    value={this.state.phoneNumber}
                    onChange={this.handleFormInput}
                    required
                  />
                  <label>Reason for transaction(Narration)</label>
                  <input
                    id="transDescription"
                    type="text"
                    value={this.state.transDescription}
                    onChange={this.handleFormInput}
                    required
                  />



                  <button
                    className={
                      this.state.phoneNumber !== "" &&
                        this.state.amount !== ""
                        ? "SignUpFormsSubmit"
                        : "SignUpFormsSubmitDisabled"
                    }
                    disabled={
                      this.state.phoneNumber !== "" &&
                        this.state.amount !== ""
                        ? false
                        : true
                    }
                    onClick={() => this.handleSubmit()}
                  >
                    {this.state.isLoading ? (
                      <CircularProgress style={{ color: "white" }} size={20} />
                    ) : (
                      "Confirm"
                    )}
                  </button>
                </div>
              </MuiThemeProvider>
            </div>
          </div>
        )}
        {this.state.view === 1 && (
          <div style={{ marginTop: "10px" }} className="ContentSpace">
            <div className="PersonalInfo PaymentsForm">
              <div
                className="ContentHeader"
                style={{ textAlign: "center", marginBottom: "35px" }}
              >
                <span
                  className="MainContentHeader Bold"
                  style={{ display: "block", marginBottom: "10px" }}
                >
                  {this.state.transMessage}{" "}
                </span>
                <span className="MainContentSubheader">
                  You are sending money from your investment account
                </span>

                <div style={{ height: "235px" }} className="SendMoneyBalance">
                  <span className="MainContentSubheader">
                    Amount to be credited to your MPESA
                  </span>

                  <span
                    style={{ color: "#252b33", marginBottom: "20px" }}
                    className="BlueBold"
                  >
                    KES {this.state.amount}
                  </span>
                  <br />
                  <span className="MainContentSubheader">
                    Amount removed from your investment account
                  </span>

                  <span className="BlueBold">KES {this.state.totalAmount}</span>
                  <span className="MainContentSubheader">Transaction fee: {this.state.charges}</span>
                  <span style={{ display: "block" }} className="MainContentSubheader">Transaction Reference Number: {this.state.paymentRef}</span>
                </div>
                <div className="SignFormDetails">
                  <div
                    className="DisplayFlexSpace"
                    style={{ marginBottom: "20px" }}
                  >
                    <div className="Verification">
                      <input
                        name="ssn-0"
                        id="0"
                        type="text"
                        autoComplete="off"
                        value={this.state.verificationCode[0]}
                        onChange={this.handleVerificationInput}
                        required
                      />
                    </div>
                    <div className="Verification">
                      <input
                        name="ssn-1"
                        id="1"
                        type="text"
                        maxLength="1"
                        autoComplete="off"
                        value={this.state.verificationCode[1]}
                        onChange={this.handleVerificationInput}
                        required
                      />
                    </div>
                    <div className="Verification">
                      <input
                        name="ssn-2"
                        id="2"
                        type="text"
                        maxLength="1"
                        autoComplete="off"
                        value={this.state.verificationCode[2]}
                        onChange={this.handleVerificationInput}
                        required
                      />
                    </div>
                    <div className="Verification">
                      <input
                        name="ssn-3"
                        id="3"
                        type="text"
                        maxLength="1"
                        autoComplete="off"
                        value={this.state.verificationCode[3]}
                        onChange={this.handleVerificationInput}
                        required
                      />
                    </div>
                    <div className="Verification">
                      <input
                        name="ssn-4"
                        id="4"
                        type="text"
                        maxLength="1"
                        autoComplete="off"
                        value={this.state.verificationCode[4]}
                        onChange={this.handleVerificationInput}
                        onKeyPress={(e) => {
                          this.handleKeyPress(e);
                        }}
                        required
                      />
                    </div>
                  </div>
                </div>
                <button
                  className="SignUpFormsSubmit"
                  onClick={() => this.handleValidation()}
                >
                  {this.state.isLoading ? (
                    <CircularProgress style={{ color: "white" }} size={20} />
                  ) : (
                    "Send money"
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default CashOutMpesa;
