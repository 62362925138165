import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  CircularProgress,
  Hidden,

} from "@material-ui/core";
//import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import GaugeChart from "react-gauge-chart";
import '../../Styles/gauge-chart.css';
import Gauge from "./Gauge";
import SignalBar from "./SignalBar";
import Odometer from "./Odometer";
//import Compass from "./Compass";
import GoogleMap from "./GoogleMap";
import ShareIcon from "@material-ui/icons/Share";
import RefreshIcon from "@material-ui/icons/Refresh";
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { format } from "date-fns";
import BatteryGauge from 'react-battery-gauge'

const batteryCustomization = {
  batteryBody: {
    strokeWidth: 4,
    cornerRadius: 6,
    fill: 'none',
    strokeColor: '#e6e6e6'
  },
  batteryCap: {
    fill: 'none',
    strokeWidth: 4,
    strokeColor: '#e6e6e6',
    cornerRadius: 2,
    capToBodyRatio: 0.4
  },
  batteryMeter: {
    fill: 'grey',
    lowBatteryValue: 15,
    lowBatteryFill: 'red',
    outerGap: 1,
    noOfCells: 1, // more than 1, will create cell battery
    interCellsGap: 1
  },
  readingText: {
    lightContrastColor: '#111',
    darkContrastColor: '#fff',
    lowBatteryColor: 'red',
    fontFamily: 'Helvetica',
    fontSize: 20,
    showPercentage: true
  },
  chargingFlash: {
    scale: undefined,
    fill: 'orange',
    animated: true,
    animationDuration: 1000
  },
};


const mapStyles = {
  position: "absolute",
  top: "-30px",
};


const useStyles = makeStyles(() => ({
  paper: {
    boxShadow: "none",
    backgroundColor: "rgba(255, 255, 255, 0.63)",
    borderRadius: "5px",
    border: "solid 1px #f1f1f1",
    minHeight: "175px",
    padding: "20px 15px"
  },
  smallPaper: {
    boxShadow: "none",
    backgroundColor: "rgba(255, 255, 255, 0.63)",
    borderRadius: "5px",
    border: "solid 1px #f1f1f1",
    minHeight: "124px",
    padding: "10px 15px"
  },
  paper2: {
    position: "relative",
    boxShadow: "none",
    backgroundColor: "rgba(255, 255, 255, 0.63)",
    height: "300px",
  },
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  circle: {
    strokeLinecap: "round",
  },
}));




const VehicleDetails = (props) => {

  const [data, setData] = useState(props.carDetails);

  const CurrentAssetsMap = useSelector((state) => state.AssetsMap[data.ImeiNumber]);

  const classes = useStyles();

  let bat, satellite;

  if (data) {
    bat = data.vehicleDetails.externalBattery.split(" ");
    bat = parseFloat(bat[0]);
    satellite = data.vehicleDetails.satelites

  }


  useEffect(() => {

    if (CurrentAssetsMap) {

      setData(CurrentAssetsMap.vehicleDetails)

      setData(CurrentAssetsMap);
    }

  }, [CurrentAssetsMap, CurrentAssetsMap?.updateKey])


  let BatteryRefreshDiv = (props) => {
    return <BatteryGauge animated={true} size={50} value={props.value}
      customization={batteryCustomization}
    />
  }



  useEffect(e => {

    setData(props.carDetails)

  }, [props.carDetails])

  return (
    <div style={{ background: "#ffffff", padding: "35px" }}>

      <div className="ContentHeader">
        <div style={{ display: "flex" }}>
          <span
            style={{ marginRight: "75px" }}
            className="MainContentHeader Bold"
          >
            {" "}
            {data.Make} {data.Model}
          </span>
          <div
            className={
              data.vehicleDetails.vstatus === "Moving"
                ? "InProgress"
                : data.vehicleDetails.vstatus === "Idling"
                  ? "Idle"
                  : "Completed"
            }
            style={{ float: "right" }}
          >
            {data.vehicleDetails.vstatus}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <div>
            <span className="MainContentSubheader">{data.NumberPlate} </span>
          </div>

          <button
            style={{
              display: "flex",
              alignItems: "center",
              color: "#002ad1",
            }}
            className="RedirectButton"
            onClick={() =>
              setTimeout(function () {
                window.location.reload();
              }, 1000)
            }
          >
            <RefreshIcon style={{ marginRight: "10px" }} />
            Update status
          </button>
        </div>
      </div>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper
            className={classes.paper}
          //style={{ minHeight: "236px", padding: "20px" }}
          >
            <div className="DisplayFlex">
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "5px",
                    }}
                  >
                    <span style={{ width: "150px" }} className="BlackText">
                      Time
                    </span>
                    <span
                      className="GreyText Bold"
                      style={{ textAlign: "right" }}
                    >
                      {format(new Date(data.vehicleDetails.Time), 'dd MMM yyyy HH:mm:ss ')}
                    </span>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <span style={{ width: "150px" }} className="BlackText">
                    Current Location
                  </span>
                  <span
                    className="GreyText Bold"
                    style={{ textAlign: "right" }}
                  >
                    {data.vehicleDetails.Location}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    marginBottom: "20px",
                  }}
                >
                  <ShareIcon
                    style={{ color: "#f58730", marginRight: "10px" }}
                  />
                  <div>
                    <button
                      className="RedirectButton"
                      style={{
                        textAlign: "right",
                        display: "block",
                        marginTop: "10px",
                        marginBottom: "20px",
                      }}
                    >
                      Share live location
                    </button>
                  </div>
                </div>
              </div>
              <div style={{ textAlign: "right" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <span
                    style={{ width: "150px", textAlign: "left" }}
                    className="BlackText"
                  >
                    Longitude
                  </span>
                  <span
                    className="GreyText Bold"
                    style={{ textAlign: "right" }}
                  >
                    {data.vehicleDetails.Longitude}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <span
                    style={{ width: "150px", textAlign: "left" }}
                    className="BlackText"
                  >
                    Latitude
                  </span>
                  <span
                    className="GreyText Bold"
                    style={{ textAlign: "right" }}
                  >
                    {data.vehicleDetails.Latitude}
                  </span>
                </div>
              </div>
            </div>
          </Paper>
          <Hidden mdDown>
            <Paper className={classes.paper2} style={{ minHeight: "180px" }}>
              <GoogleMap
                carPlate={data.NumberPlate}
                data={data.vehicleDetails}
                styles={mapStyles}
                handlePopUp={null}
                handleClick={null}
              />
            </Paper>
          </Hidden>
        </Grid>
      </Grid>
      <Grid container spacing={2}>

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Paper
            className={classes.paper}
            style={{ paddingTop: "20px" }}
          >
            <div>
              <div>
                <span className="CardTitle">Live Speedometer</span>
              </div>
              <div className="FleetGauge" key={data.NumberPlate}>
                {
                  (() => {
                    var speed = +data.vehicleDetails.speed
                    return <Gauge speed={speed} />

                  })()
                }
              </div>
              <div style={{ textAlign: "center", fontSize: '12px' }}>
                <span>KMPH</span>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Paper className={classes.paper}>
            <div>
              <div style={{ marginLeft: "10px" }}>
                <span className="CardTitle">Fuel levels</span>
              </div>
              <div className="FleetGauge" key={data.NumberPlate}>
                <GaugeChart
                  id="gauge-chart1"
                  style={{ width: "200px", height: "190" }}
                  arcsLength={[0.3, 0.5, 0.2]}
                  colors={["rgb(0, 42, 209)", "rgba(0, 42, 209, 0.1)"]}
                  percent={data.vehicleDetails.FuelLevel / 100}
                  needleColor="#FFFFFF"
                  needleBaseColor="#FFFFFF"
                  textColor="black"
                  arcWidth={0.1}
                />
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Paper className={classes.paper}>
            <div style={{ marginLeft: "35px" }}>
              <span className="CardTitle">Car battery</span>
            </div>
            <div className="FleetGauge" key={data.NumberPlate}>
              <GaugeChart
                id="gauge-chart2"
                style={{ width: "200px", height: "190px !important" }}
                nrOfLevels={5}
                arcsLength={[0.3, 0.5, 0.2]}
                colors={["rgb(0, 42, 209)", "rgba(0, 42, 209, 0.1)"]}
                percent={bat / 100}
                needleColor="#FFFFFF"
                needleBaseColor="#FFFFFF"
                textColor="black"
                formatTextValue={(value) => value + "v"}
                arcWidth={0.1}
              />
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Paper className={classes.smallPaper} style={{ height: "100px" }}>
            <div style={{ width: "170px" }}>
              <div>
                <span className="CardTitle">Odometer</span>
              </div>
              <div key={data.carReg}>
                <Odometer value={data.vehicleDetails.Odometer} />
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Paper className={classes.smallPaper} >
            <div style={{ width: "170px" }}>
              <div>
                <span className="CardTitle">Satellites</span>
              </div>
              <div key={data.carReg} style={{
                position: 'relative',
                width: 70,
                height: 70,

                margin: "10px auto"
              }} >
                <div
                  style={{
                    borderRadius: "50%",
                    backgroundColor: "rgba(0, 42, 209, 0.1)",
                    height: "80px",
                    width: "80px",
                    position: 'absolute',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden"
                  }}
                >

                  <div style={{
                    position: 'relative',
                    width: 100,
                    height: 100,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>{satellite} </div>
                  <CircularProgress
                    variant="determinate"
                    value={satellite}
                    classes={{
                      circle: classes.circle,
                    }}
                    style={{ color: "black", position: 'absolute', top: 0 }}
                    size={80}
                    thickness={2}
                  />

                </div>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Paper className={classes.smallPaper}>
            <div>
              <span className="CardTitle">Tracker Status</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: '15px' }}>
              <div>

                <SignalBar signal={2} bars="one" />
                <div
                  style={{
                    color: "rgba(37, 37, 37, 0.5)",
                    fontSize: "11px", display: 'block', textAlign: 'center'
                  }}
                >
                  GSMA
                </div>
              </div>


              <Grid container direction="column" alignItems="center"  >
                {/* <Battery20Rounded
                  fontSize="large"
                  style={{
                    transform: "rotate(90deg)",
                    fontSize: "3rem",
                  }}
                /> */}
                <Grid>
                  <BatteryRefreshDiv value={+data.vehicleDetails.Battery} />

                </Grid>
                <Grid>
                  <span
                    style={{
                      color: "rgba(37, 37, 37, 0.5)",
                      fontSize: 10

                    }}
                  >
                    Battery
                  </span>
                </Grid>
              </Grid>
            </div>
          </Paper>

        </Grid>


      </Grid>
    </div >
  );

}


export default VehicleDetails