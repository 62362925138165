import React, { Component } from "react";
import {
  withStyles,
  MuiThemeProvider,

  Drawer,
  CircularProgress
} from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles'

const styles = (theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    borderRadius: "2px",
    boxShadow: "-30px 4px 60px 30px rgba(37, 43, 51, 0.03)",
    padding: "30px",
  },
});


const themeLogs = createTheme({
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(37, 43, 51, 0.5)",
        backdropFilter: "blur(2px)",
      },
    },
    MuiPaper: {
      elevation16: {
        boxShadow: "0px 9px 15px 7px rgba(70, 70, 70, 0.06)",
      },
    },
    MuiDrawer: {
      paperAnchorRight: {
        marginTop: "0px",
      },
      paperAnchorDockedLeft: {
        borderRight: "none",
      },
    },
  },
});

class AddBeneficiary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      beneficiaryName: "",
      emailAddress: "",
      country: "",
      address: "",
      bankName: "",
      accountNumber: "",
      branchName: "",
      branchAddress: "",
      swiftCode: "",
      bankCountry: "",
      bankCity: "",
      isLoading: false,
    };

    //this.handleFormInputNumber = this.handleFormInputNumber.bind(this);
    this.handleFormInput = this.handleFormInput.bind(this);
  }

  handleFormInput(event) {
    if (event.target.id) {
      this.setState({ [event.target.id]: event.target.value });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }
  handleSubmit() {
    this.setState({ isLoading: true });
    // var payload = {

    // };
  }

  render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={themeLogs}>
        <Drawer
          anchor={"right"}
          open={this.props.openModal}
          onClose={() => this.props.showModal()}
        >
          <div className={classes.paper}>
            <div className="Drawer">
              <img
                src="./icon/close.svg"
                alt="X"
                style={{ float: "right", cursor: "pointer" }}
                onClick={() => this.props.showModal()}
              />
              <br />
              <div style={{ marginTop: "30px" }}>
                <span className="MainContentHeader Bold">
                  Beneficiary bank details
                </span>
                <br />
                <span className="MainContentSubheader">
                  Fill in the beneficiary bank details below{" "}
                </span>
                <br />
                <span className="MainContentHeader Bold">
                  Personal Information
                </span>
              </div>
              <div className="ProfileForm">
                <label>Beneficiary name</label>
                <input
                  id="beneficiaryName"
                  type="text"
                  value={this.state.beneficiaryName}
                  onChange={this.handleFormInput}
                  placeholder="e.g John Hartley"
                  required
                />
                <label>Beneficiary email address</label>
                <input
                  id="beneficiaryemail"
                  type="text"
                  value={this.state.emailAddress}
                  onChange={this.handleFormInput}
                  placeholder="customer@email.com"
                  required
                />
                <label>Beneficary country of residence</label>
                <input
                  id="beneficiaryName"
                  type="text"
                  value={this.state.beneficiaryName}
                  onChange={this.handleFormInput}
                  required
                />
                <label>Beneficary address</label>
                <input
                  id="beneficiaryName"
                  type="text"
                  value={this.state.beneficiaryName}
                  onChange={this.handleFormInput}
                  required
                />
              </div>
              <div style={{ margin: '20px' }}>
                <span className="MainContentHeader Bold">
                  Account and bank details
                </span>
              </div>
              <div className="ProfileForm">
                <label>Beneficary bank name</label>
                <input
                  id="beneficiaryName"
                  type="text"
                  value={this.state.beneficiaryName}
                  onChange={this.handleFormInput}
                  required
                />
                <label>Account number</label>
                <input
                  id="beneficiaryemail"
                  type="text"
                  value={this.state.emailAddress}
                  onChange={this.handleFormInput}
                  required
                />
                <label>Branch name </label>
                <input
                  id="beneficiaryName"
                  type="text"
                  value={this.state.beneficiaryName}
                  onChange={this.handleFormInput}
                  required
                />
                <label>Branch address </label>
                <input
                  id="beneficiaryName"
                  type="text"
                  value={this.state.beneficiaryName}
                  onChange={this.handleFormInput}
                  required
                />
                <label>SWIFT/BIC Code </label>
                <input
                  id="beneficiaryName"
                  type="text"
                  value={this.state.beneficiaryName}
                  onChange={this.handleFormInput}
                  required
                />
                <label>Country </label>
                <input
                  id="beneficiaryName"
                  type="text"
                  value={this.state.beneficiaryName}
                  onChange={this.handleFormInput}
                  required
                />
                <label>City </label>
                <input
                  id="beneficiaryName"
                  type="text"
                  value={this.state.beneficiaryName}
                  onChange={this.handleFormInput}
                  required
                />
                <button
                  className={
                    this.state.beneficiaryName !== "" &&
                      this.state.emailAddress !== "" &&
                      this.state.bankName !== "" &&
                      this.state.accountNumber !== ""
                      ? "SignUpFormsSubmit"
                      : "SignUpFormsSubmitDisabled"
                  }
                  disabled={
                    this.state.beneficiaryName !== "" &&
                      this.state.emailAddress !== "" &&
                      this.state.bankName !== "" &&
                      this.state.accountNumber !== ""
                      ? false
                      : true
                  }
                  onClick={() => this.handleSubmit()}
                >
                  {this.state.isLoading ? (
                    <CircularProgress style={{ color: "white" }} size={20} />
                  ) : (
                    "Save beneficiary details"
                  )}
                </button>
              </div>

            </div>
          </div>
        </Drawer>
      </MuiThemeProvider>
    );
  }
}
export default withStyles(styles)(AddBeneficiary);
