import React, { Component } from "react";
import {
  Grid,

  MuiThemeProvider,
  Drawer
} from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles'
import Benefits from "./Benefits";
import InvestmentPlan from "./InvestmentPlan";
import CarImages from "./CarImages";
import { validateEmail } from "../../Utilities/SharedFunctions";
import { withRouter } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

const themeShowroomCarDetails = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  overrides: {
    MuiPaper: {
      root: {
        transition: "none !important"
      }
    },
    MuiButton: {
      root: {
        minWidth: "auto !important"
      }
    },
    MuiDrawer: {
      paper: {
        alignItems: "center",
        position: "inherit"
      },
      paperAnchorDockedTop: {
        borderBottom: "none",
        marginBottom: "50px"
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(37, 43, 51, 0.5)",
        backdropFilter: "blur(2px)"
      }
    }
  }
});

class ShowroomCarDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      validEmail: false,
      specOpen: false,
      techOpen: false
    };
    this.handleFormInput = this.handleFormInput.bind(this);
  }

  handleFormInput(event) {
    this.setState({ [event.target.id]: event.target.value });
    if (event.target.id === "email") {
      this.setState({ validEmail: validateEmail(event.target.value) });
    }
  }

  CapitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  handleDrawerToggle = item => {
    if (item === "spec") {
      this.setState({ specOpen: !this.state.specOpen });
    } else {
      this.setState({ techOpen: !this.state.techOpen });
    }
  };

  render() {
    var CurrencyFormat = require("react-currency-format");
    var car = this.props.selectedCar.Data;
    var rate = this.props.rate;
    var features = this.props.selectedCar.StandEquip;

    const rows = [...Array(Math.ceil(features.length / 2))];
    const carFeatures = rows.map((row, idx) =>
      features.slice(idx * 2, idx * 2 + 2)
    );

    return (
      <div style={{ marginTop: "65px" }}>
        <div className="LandingPagePadding">
          <button
            className="BackButton"
            style={{ color: "#002AD1" }}
            onClick={() => this.props.handleView(0)}
          >
            <img
              src="/icon/backIcon.svg"
              alt="back"
              style={{ marginRight: "10px" }}
            />
            Back to search results page
          </button>
          <div className="DisplayFlexA" style={{ margin: "20px 0" }}>
            <button
              className="BackButtonA"
              onClick={() => {
                this.props.history.push("/");
                localStorage.setItem("landingPageMenu", "How");
              }}
            >
              Home
            </button>
            <img src="/icon/left.svg" alt=">" />
            <button
              className="BackButtonA"
              onClick={() => this.props.handleView(0)}
            >
              Cars
            </button>
            <img src="/icon/left.svg" alt=">" />
          </div>
          <div style={{ marginBottom: "40px" }}>
            <span className="Bold" style={{ fontSize: "16px" }}>
              {car.Name}
            </span>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <div>
                <CarImages images={car.ImagesLink} />
              </div>
              <div style={{ marginTop: "100px" }}>
                <div className="DisplayFlexB">
                  <div className="ShowroomCarDetails">
                    <img
                      src="/icon/calendar.svg"
                      alt="year"
                      style={{ marginRight: "10px" }}
                    />
                    <span className="BlackText">{car.Year}</span>
                  </div>
                  <div className="ShowroomCarDetails">
                    <img
                      src="/icon/gas.svg"
                      alt="year"
                      style={{ marginRight: "10px" }}
                    />
                    <span className="BlackText">
                      {this.CapitalizeFirstLetter(car.FuelType)}
                    </span>
                  </div>
                  <div className="ShowroomCarDetails">
                    <img
                      src="/icon/speed.svg"
                      alt="year"
                      style={{ marginRight: "10px" }}
                    />
                    <span className="BlackText">{car.Year}</span>
                  </div>
                </div>
                <div className="DisplayFlexB">
                  <div className="ShowroomCarDetails">
                    <img
                      src="/icon/color.svg"
                      alt="year"
                      style={{ marginRight: "10px" }}
                    />
                    <span className="BlackText">{car.Color}</span>
                  </div>
                  <div className="ShowroomCarDetails">
                    <img
                      src="/icon/transmission.svg"
                      alt="year"
                      style={{ marginRight: "10px" }}
                    />
                    <span className="BlackText">
                      {this.CapitalizeFirstLetter(car.Transmission)}
                    </span>
                  </div>
                  <div className="ShowroomCarDetails">
                    <img
                      src="/icon/transmission.svg"
                      alt="year"
                      style={{ marginRight: "10px" }}
                    />
                    <span className="BlackText">
                      {this.CapitalizeFirstLetter(car.Transmission)}
                    </span>
                  </div>
                </div>
              </div>
              <MuiThemeProvider theme={themeShowroomCarDetails}>
                <div
                  className="DisplayFlex1"
                  style={{ marginTop: "35px", cursor: "pointer" }}
                  onClick={() => this.handleDrawerToggle("spec")}
                >
                  <div>
                    <span>Standard equipment</span>
                  </div>
                  <div>
                    <img
                      src={
                        this.state.specOpen
                          ? "/icon/down.svg"
                          : "/icon/closed.svg"
                      }
                      alt="down"
                    />
                  </div>
                </div>
                <Drawer
                  variant="persistent"
                  anchor="top"
                  open={this.state.specOpen}
                  style={!this.state.specOpen ? { display: "none" } : null}
                  onClick={() => this.handleDrawerToggle("spec")}
                >
                  <CSSTransition
                    in={this.state.specOpen}
                    timeout={200}
                    classNames="slide"
                  >
                    <div
                      className="InvestmentMessageAnimation"
                      style={{ width: "100%", marginTop: "20px" }}
                    >
                      {carFeatures.map((row, idx) => (
                        <div className="DisplayFlexD" key={row.name}>
                          {row.map((feature, index) => (
                            <div
                              className="ShowroomCarDetailsA DisplayFlexD"
                              key={feature.code}
                            >
                              <img
                                src="/icon/addB.svg"
                                alt="+"
                                style={{ marginRight: "10px" }}
                              />
                              <span className="LandingPageBlackText">
                                {feature.name}
                              </span>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </CSSTransition>
                </Drawer>
                <div
                  className="DisplayFlex1"
                  style={{ marginTop: "35px", cursor: "pointer" }}
                  onClick={() => this.handleDrawerToggle("tech")}
                >
                  <div>
                    <span>Technical specifications</span>
                  </div>
                  <div>
                    <img
                      src={
                        this.state.techOpen
                          ? "/icon/down.svg"
                          : "/icon/closed.svg"
                      }
                      alt="down"
                    />
                  </div>
                </div>
                <Drawer
                  variant="persistent"
                  anchor="top"
                  open={this.state.techOpen}
                  style={!this.state.techOpen ? { display: "none" } : null}
                  onClick={() => this.handleDrawerToggle("tech")}
                >
                  <CSSTransition
                    in={this.state.techOpen}
                    timeout={200}
                    classNames="slide"
                  >
                    <div
                      className="InvestmentMessageAnimation"
                      style={{ width: "100%", marginTop: "20px" }}
                    >
                      {carFeatures.map((row, idx) => (
                        <div className="DisplayFlexD" key={row.name}>
                          {row.map((feature, index) => (
                            <div
                              className="ShowroomCarDetailsA DisplayFlexD"
                              key={feature.code}
                            >
                              <img
                                src="/icon/addB.svg"
                                alt="+"
                                style={{ marginRight: "10px" }}
                              />
                              <span className="LandingPageBlackText">
                                {feature.name}
                              </span>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </CSSTransition>
                </Drawer>
              </MuiThemeProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <div className="InvestmentMessageA">
                <div
                  className="ShowroomCarDetailsB"
                  style={{ marginBottom: "15px" }}
                >
                  <div>
                    <span className="BlackText" style={{ fontSize: "16px" }}>
                      Price details
                    </span>
                  </div>
                  <div
                    className={
                      car.UsedType === "Locally Used"
                        ? "UseStatus Local"
                        : "UseStatus Foreign"
                    }
                  >
                    {this.CapitalizeFirstLetter(car.UsedType)}
                  </div>
                </div>
                <div>
                  <span
                    className="BlackText"
                    style={{ fontSize: "16px", fontWeight: "900" }}
                  >
                    USD{" "}
                    <CurrencyFormat
                      value={parseFloat(car.CarPrice).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </span>
                </div>
                <div>
                  <span className="GreyText">
                    Estimated KES price = KES{" "}
                    <CurrencyFormat
                      value={parseFloat(car.CarPrice * rate).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </span>
                </div>
                <div style={{ marginTop: "36px" }}>
                  <div className="DisplayFlexB">
                    <div className="ShowroomCarDetailsA DisplayFlexD">
                      <img
                        src="/icon/location.svg"
                        alt="location"
                        style={{ marginRight: "10px" }}
                      />
                      <span className="BlackText" style={{ fontSize: "16px" }}>
                        Location
                      </span>
                    </div>
                    <div className="ShowroomCarDetailsA">
                      <span className="BlackText" style={{ fontSize: "16px" }}>
                        Japan in transit to Kenya
                      </span>
                    </div>
                  </div>
                  <div className="DisplayFlexB">
                    <div className="ShowroomCarDetailsA">
                      <span className="BlackText" style={{ fontSize: "16px" }}>
                        Taxes and fees
                      </span>
                    </div>
                    <div className="ShowroomCarDetailsA">
                      <span className="BlackText" style={{ fontSize: "16px" }}>
                        Included
                      </span>
                    </div>
                  </div>
                  <div className="DisplayFlexB">
                    <div className="ShowroomCarDetailsA">
                      <span className="BlackText" style={{ fontSize: "16px" }}>
                        Import details
                      </span>
                    </div>
                    <div className="ShowroomCarDetailsA">
                      <button
                        className="RedirectButton"
                        style={{
                          fontSize: "16px",
                          color: "#18191f"
                        }}
                      >
                        Learn more
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ShowroomButton">
                <button
                  className="SignUpFormsSubmit"
                  onClick={() => this.props.handleView(2)}
                >
                  Proceed to checkout
                </button>
              </div>
              <div className="ShowroomPlan">
                <InvestmentPlan
                  type="Full"
                  percentage="100%"
                  currency="USD"
                  amount={car.CarPrice}
                />
              </div>
              <div className="ShowroomPlan" style={{ marginTop: "32px" }}>
                <InvestmentPlan
                  type="Partial"
                  percentage="50%"
                  currency="USD"
                  amount={car.CarPrice * 0.5}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <Benefits />

        <div className="LandingPagePadding">
          <div
            className="AlertContainer"
            style={{
              background: `url("./icon/BG Mask.svg")`
            }}
          >
            <div>
              <img src="/icon/alerts.svg" alt="alerts" />
            </div>
            <div>
              <div style={{ width: "80%", marginRight: "20px" }}>
                <span
                  className="LandingPageHeader"
                  style={{ color: "#ffffff", fontWeight: "900" }}
                >
                  Receive alerts on new deals
                </span>
              </div>
              <div className="SubscriptionDiv">
                <input
                  id="email"
                  type="email"
                  placeholder="Your work email address"
                  value={this.state.email}
                  style={
                    this.state.email !== "" && this.state.validEmail !== true
                      ? { border: "1px solid #F05050" }
                      : null
                  }
                  onChange={this.handleFormInput}
                  required
                />
                <button>Subscribe</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ShowroomCarDetails);
