import { configureStore } from '@reduxjs/toolkit'
import ReduxSlice from './ReduxSlice'
import storage from 'redux-persist/lib/storage';


import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';



const persistConfig = {
    key: 'counter',
    storage,
};

const persistedReducer = persistReducer(persistConfig, ReduxSlice);


export default configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

// export default configureStore({
//     reducer: {
//         AppStore: ReduxSlice
//     }
// })