import { createSlice } from '@reduxjs/toolkit'

const lookUpKeys = [
    "Altitude",
    "Battery",
    "FuelLevel",
    "Latitude",
    "Location",
    "Longitude",
    "Odometer",
    "Speed",
    "Time",
    "vstatus",
    "altitude",
    "battery",
    "externalBattery",
    "fuelLevel",
    "latitude",
    "location",
    "longitude",
    "odometer",
    "speed",
    "time",

]



export const ReduxSlice = createSlice({
    name: 'AppState',
    initialState: {
        AssetsMap: {},
        intervalReminder: 0,
        Client: {}
    },
    reducers: {
        AddAsset: (state, action) => {

            var asset = action.payload;

            state.AssetsMap[asset.Id] = asset;

        },
        AddMultipleAssets: (state, action) => {

            state.AssetsMap = {};

            var assets = action.payload;

            assets.forEach(asset => state.AssetsMap[asset.vehicleDetails.ImeiNumber] = { ...asset, updateKey: 0 });

        },

        UpdateVehicleDetailsMap: (state, action) => {

            let details = { ...state.AssetsMap };

            action.payload.forEach(_api_asset => {

                if (details[_api_asset.ImeiNumber]) {

                    let changeFound = false;

                    for (var i = 0; i < lookUpKeys.length; i++) {
                        if (_api_asset[lookUpKeys[i]] === details[_api_asset.ImeiNumber].vehicleDetails[lookUpKeys[i]]) {
                            changeFound = true;
                            break;
                        }

                    }



                    if (changeFound) {

                        details[_api_asset.ImeiNumber].updateKey = Math.random();
                        details[_api_asset.ImeiNumber].vehicleDetails = _api_asset;
                    }



                }
            });

            state.AssetsMa = details;

            //////////////

        },
        GetAssets: state => {
            return state
        },
        UpdateAsset: (state, action) => {
            state.AssetsMap = action.payload
        },

        UpdateAssets: (state, action) => {
            state.AssetsMap = action.payload
        },

        UpdateIntervalReminder: (state, action) => {
            state.intervalReminder = action.payload
        },

        UpdateClient: (state, action) => {
            state.Client = action.payload
        }
    }
})

export const { AddAsset, GetAssets,
    UpdateAsset,
    AddMultipleAssets,
    UpdateVehicleDetailsMap,
    UpdateIntervalReminder,
    UpdateClient
} = ReduxSlice.actions

export default ReduxSlice.reducer

