import React, { Component } from "react";
import {
  withStyles,
  Button,
  useTheme,

  ThemeProvider
} from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles'
import MobileStepper from "@material-ui/core/MobileStepper";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";

const StepperOverride = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  overrides: {
    MuiMobileStepper: {
      dotActive: {
        backgroundColor: "#003C58"
      }
    },
    MuiPaper: {
      root: {
        color: "#003C58 !important"
      }
    },
    MuiButton: {
      root: {
        color: "none !important",
        position: "inherit",
        padding: "0px !important"
      }
    },
    MuiButtonBase: {
      root: {
        justifyContent: "left"
      }
    },
    MuiSvgIcon: {
      root: {
        position: "inherit"
      }
    },
    MuiSwitch: {
      colorSecondary: {
        "&&:hover": {
          backgroundColor: "transparent !important"
        }
      }
    }
  }
});

const StepperCard = withStyles({
  root: {
    padding: "2px",
    margin: "20px auto",
    backgroundColor: "transparent",
    width: "100%",
    float: "right",
    fontSize: "14px"
  }
})(MobileStepper);

const StepperButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      boxShadow: "none",
      //   color: COLOURS.text,
      backgroundColor: "transparent"
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "transparent"
    },
    "&:focus": {
      boxShadow: "none",
      backgroundColor: "transparent"
    }
  }
})(Button);

class CarImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0
    };
  }

  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  render() {
    const theme = useTheme;
    var images = this.props.images;
    var steps = this.props.images.length;
    var activeStep = this.state.activeStep;

    return (
      <ThemeProvider theme={StepperOverride}>
        <SwipeableViews index={activeStep}>
          {steps > 0 &&
            images.map((image, index) => (
              <div key={index}>
                {Math.abs(activeStep - index) <= steps ? (
                  <div
                    className="ShowroomCarSlides"
                    style={{
                      background: `url(${image})`
                    }}
                  ></div>
                ) : null}
              </div>
            ))}
        </SwipeableViews>
        <StepperCard
          variant="text"
          steps={steps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <StepperButton
              size="small"
              onClick={this.handleNext}
              disabled={activeStep === steps - 1}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </StepperButton>
          }
          backButton={
            <StepperButton
              size="small"
              onClick={this.handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
            </StepperButton>
          }
        />
      </ThemeProvider>
    );
  }
}
export default CarImages;
