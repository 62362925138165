import React, { Component } from "react";
import moment from "moment";
import Graph from "../SharedComponents/GraphC";
import WeekSelect from "./WeekSelect";
import { getRequest } from "../../Services/FetchFunctions";

class BookingRevenue extends Component {
  constructor() {
    super();
    this.state = {
      filteredAccount: [],
      accounts: []
    }
    this.handleSelectedPeriod = this.handleSelectedPeriod.bind(this);
  }
  componentDidMount() {
    // this.getTopCar();
    this.getInvestments();
  }

  handleSelectedPeriod(value) {
    this.props.handleSelectedPeriod(value);
  }
  getInvestments() {
    const url = "client/account/get-accounts";
    getRequest(url)
      .then((resp) => {
        var accounts = resp.data.List;
        var filteredAccount = accounts.filter(
          (account) => account.AccountType === "INVESTMENT"
        );
        this.setState({
          accounts: resp.data.List,
          filteredAccount: filteredAccount,
        });
        this.setState({
          filteredAccount: filteredAccount,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  render() {
    var account = this.state.filteredAccount[0];

    if (account !== undefined) {
      var curr = account.AccountCurrency;

    }
    var duration = this.props.selectedPeriod;
    var period = 0;
    var dateLabel = [];
    if (duration === "W") {
      period = 6;
    }

    for (var i = period; i >= 0; i--) {
      var newDate = moment().add(-i, "days").format("ddd DD");

      dateLabel.push(newDate);
    }

    return (
      <div className="Cards">
        <div className="DisplayFlex" style={{ alignItems: "center" }}>
          <div>
            <span className="CardTitle">Booking revenue trends</span>
          </div>
          <div>
            <WeekSelect
              selectedPeriod={this.props.selectedPeriod}
              handleSelectedPeriod={this.handleSelectedPeriod}
            />
          </div>
        </div>
        <div style={{ marginTop: "25px" }}>
          <Graph
            currency={curr}
            height={180}
            data={this.props.revenue}
            label={dateLabel}
            tooltipLabel="Revenue"
          />
        </div>
      </div>
    );
  }
}

export default BookingRevenue;
