import React, { Component } from "react";
import {
  withStyles,

  Modal,
  Backdrop,
  MuiThemeProvider,
  CircularProgress,
  MenuItem,
  Select,
} from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles'
import { post_request } from "../../Services/FetchFunctions";
import CustomSnackbar from "../SharedComponents/CustomSnackbar";

const stylesModal = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    background: "none",
    border: "none",
    borderRadius: "5px",
    boxShadow: "inherit",
  },
  container: {
    minHeight: 450,
    overflowX: "hidden",
  },
  selectIcon: {
    display: "none",
  },
  backdrop: {
    backgroundColor: "rgba(37, 43, 51, 0.5)",
  },
});
const MenuProps = {
  style: {
    marginTop: "15px",
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

const themeModal = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(37, 43, 51, 0.5)",
        backdropFilter: "blur(2px)",
      },
    },
  },
});

class LockAccount extends Component {
  constructor() {
    super();
    this.state = {
      otp: "",
      lockAmount: "",
      lockTitle: "",
      selectedCurrency: "KES",
      view: 0,
      isLoading: false,
      responseStatus: "",
      snackbaropen: false,
      snackbarmsg: "",
      snackbartxt: "",
      errorMessage: "",
    };

    this.handleFormInput = this.handleFormInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  // componentDidMount() {
  //   console.log(this.props.account);
  // }
  handleFormInput(event) {
    this.setState({ [event.target.id]: event.target.value });
  }
  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };
  handleSubmit() {
    this.setState({ isLoading: true });
    const url = "client/account/lock-account";
    let account = this.props.account;
    let payload = {
      account: account.AccountNumber,
      amount: this.state.lockAmount,
    };

    post_request(url, payload)
      .then((res) => {
        console.log(res);
        this.props.handleOpen();
        this.setState({
          isLoading: false,
          responseStatus: "success",
          snackbarmsg: "Account locked successfully",
          snackbartxt: "We'll notify you once the value reaches maturity",
          snackbaropen: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        console.log(err.response);
        this.setState({
          isLoading: false,
          errorMessage: err.response.data.Message,
        });
      });
  }

  render() {
    const { classes } = this.props;
    let CurrencyFormat = require("react-currency-format");
    return (
      <div>
        <CustomSnackbar
          style={{ boxShadow: "-30px 4px 60px 30px rgba(37, 43, 51, 0.03)" }}
          hideAlert={this.snackbarClose}
          showSnack={this.state.snackbaropen}
          hideSnack={this.snackbarClose}
          response={this.state.responseStatus}
          title={this.state.snackbarmsg}
          messagetxt={this.state.snackbartxt}
        />
        <MuiThemeProvider theme={themeModal}>
          <Modal
            className={classes.modal}
            open={this.props.setOpen}
            onClose={() => this.props.handleOpen()}
            closeAfterTransition
            BackdropComponent={Backdrop}
          >
            <div className={classes.paper}>
              <div style={{ width: "710px" }} className="ModalPaper">
                <div className="ModalHeader" style={{ padding: "0px" }}>
                  <div>
                    <button
                      className="BackButton"
                      style={{ float: "right", marginBottom: "0px" }}
                      onClick={() => this.props.handleOpen()}
                    >
                      <img src="./icon/close.svg" alt="x" />
                    </button>
                  </div>
                  <div
                    style={{ alignItems: "center", display: "inline-block" }}
                  >
                    {this.state.view === 0 ? (
                      <span
                        className="MainContentHeader"
                        style={{ fontSize: "20px", marginBottom: "15px" }}
                      >
                        Lock investment account
                      </span>
                    ) : (
                      <>
                        <span
                          className="MainContentHeader"
                          style={{ fontSize: "20px", marginBottom: "15px" }}
                        >
                          Confirm lock details
                        </span>
                        <br />
                        <button
                          className="BackButton"
                          onClick={() => this.setState({ view: 0 })}
                        >
                          <img src="/icon/back.svg" alt="back" />
                          Back
                        </button>
                      </>
                    )}

                    <br />
                    <div style={{ textAlign: "left" }} className="WarningBar">
                      <img src="/icon/warning.svg" alt="warning" />
                      <span>
                        Once a lock is initiated, you'll be required to contact
                        customer experience to unlock account. Please ensure you
                        read through all details carefully before continuing.
                      </span>
                    </div>
                  </div>
                </div>
                {this.state.view === 0 && (
                  <>
                    <div>
                      <p className="MainContentSubheader">
                        Locking your account is ideal for those wishing to keep
                        investment returns safely. This allows you to save
                        returns upto a specified amount, for a period of time.
                        <br />
                        <br />
                        Once this amount is reached in your account, you'll
                        receive a notification and will be able to unlock the
                        account to utilize the money.
                      </p>
                    </div>
                    <div className="ProfileForm" style={{ marginTop: "20px" }}>
                      <label>Select Lock amount</label>

                      <div
                        style={{ margin: "20px 0" }}
                        className="DisplayFlexSpace"
                      >
                        <div
                          id="315"
                          onClick={() =>
                            this.setState({
                              lockTitle: "315",
                              lockAmount: "315000",
                            })
                          }
                          className={
                            this.state.lockTitle === "315"
                              ? "LockAmountDiv"
                              : "LockAmountDiv_Disabled"
                          }
                        >
                          KES 315,000.00
                        </div>
                        <div
                          id="415"
                          onClick={() =>
                            this.setState({
                              lockTitle: "415",
                              lockAmount: "415000",
                            })
                          }
                          className={
                            this.state.lockTitle === "415"
                              ? "LockAmountDiv"
                              : "LockAmountDiv_Disabled"
                          }
                        >
                          KES 415,000.00
                        </div>
                        <div
                          id="515"
                          onClick={() =>
                            this.setState({
                              lockTitle: "515",
                              lockAmount: "515000",
                            })
                          }
                          className={
                            this.state.lockTitle === "515"
                              ? "LockAmountDiv"
                              : "LockAmountDiv_Disabled"
                          }
                        >
                          KES 515,000.00
                        </div>
                        <div
                          id="custom"
                          onClick={() => this.setState({ lockTitle: "custom" })}
                          className={
                            this.state.lockTitle === "custom"
                              ? "LockAmountDiv"
                              : "LockAmountDiv_Disabled"
                          }
                        >
                          Custom
                        </div>
                      </div>
                    </div>
                    {this.state.lockTitle === "custom" ? (
                      <div className="SignUpFormsTwoInOne">
                        <div className="SignUpFormsTwoInOneSmall">
                          <Select
                            className="PaymentSelect"
                            style={{
                              fontFamily: "inherit",
                              fontSize: "14px",
                              marginTop: "0px",
                            }}
                            name="selectedCurrency"
                            value={this.state.selectedCurrency}
                            // onChange={event => this.handleFormInput(event)}
                            disableUnderline={true}
                            MenuProps={MenuProps}
                            disabled
                          >
                            <MenuItem value="KES"> KES</MenuItem>
                          </Select>
                        </div>
                        <input
                          id="lockAmount"
                          type="text"
                          value={this.state.lockAmount}
                          onChange={this.handleFormInput}
                          required
                        />
                      </div>
                    ) : null}
                    {this.state.lockAmount !== "" ? (
                      <div style={{ margin: "30px 0", textAlign: "center" }}>
                        <span style={{ color: "#002ad1" }}>
                          Lock investment account up to{" "}
                          <b>
                            {" "}
                            KES{" "}
                            <CurrencyFormat
                              value={parseFloat(this.state.lockAmount).toFixed(
                                2
                              )}
                              displayType={"text"}
                              thousandSeparator={true}
                            />{" "}
                          </b>
                        </span>
                      </div>
                    ) : null}

                    <div
                      style={{ justifyContent: "center" }}
                      className="ModalFooter"
                    >
                      <button
                        style={{ width: "300px" }}
                        className={
                          this.state.lockAmount !== ""
                            ? "SignUpFormsSubmit"
                            : "SignUpFormsSubmitDisabled"
                        }
                        disabled={this.state.lockAmount !== "" ? false : true}
                        onClick={() => this.setState({ view: 1 })}
                      >
                        {this.props.isLoading ? (
                          <CircularProgress
                            style={{ color: "white" }}
                            size={20}
                          />
                        ) : (
                          "Confirm"
                        )}
                      </button>
                    </div>
                  </>
                )}
                {this.state.view === 1 && (
                  <>
                    <div
                      style={{
                        height: "70px",
                        margin: "10px auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        style={{ height: "70px" }}
                        src="/icon/lockSuccess.svg"
                        alt="lock"
                      />
                    </div>
                    <div style={{ margin: "30px 0", textAlign: "center" }}>
                      <span style={{ color: "#002ad1" }}>
                        Lock investment account up to{" "}
                        <b>
                          {" "}
                          KES{" "}
                          <CurrencyFormat
                            value={parseFloat(this.state.lockAmount).toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                          />{" "}
                        </b>
                      </span>
                    </div>
                    {this.state.errorMessage !== "" && (
                      <div style={{ textAlign: "center", margin: "30px auto" }}>
                        <span style={{ color: "red" }}>
                          {this.state.errorMessage}
                        </span>
                      </div>
                    )}
                    <div
                      style={{ justifyContent: "center" }}
                      className="ModalFooter"
                    >
                      <button
                        style={{ width: "300px" }}
                        className={
                          this.state.lockAmount !== ""
                            ? "SignUpFormsSubmit"
                            : "SignUpFormsSubmitDisabled"
                        }
                        disabled={this.state.lockAmount !== "" ? false : true}
                        onClick={() => this.handleSubmit()}
                      >
                        {this.state.isLoading ? (
                          <CircularProgress
                            style={{ color: "white" }}
                            size={20}
                          />
                        ) : (
                          "Confirm"
                        )}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Modal>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withStyles(stylesModal)(LockAccount);
