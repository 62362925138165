import React, { Component } from "react";
import TopBar from "../../Components/SharedComponents/TopBar";
import Menu from "../../Components/SharedComponents/Menu";
import { Grid,Paper } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const paperStyle = {
  width:'354px',
  height:'166px',
  boxShadow: '0 4px 9px 0 rgba(37, 43, 51, 0.02)',
  border:'solid 1px #f1f1f2',
  padding:' 24px 66px 32px 24px',
  borderRadius: '5px'
}

export default class Accounts extends Component {
  render() {
    return (
      <div className="ContainerApp">
        <TopBar />
        <div className="MainBody">
          <div className="Menu">
            <Menu />
          </div>
          <div className="Content">
            <div className="ContentHeader">
              <span className="MainContentHeader Bold">Accounts</span>
              <br />
              <span className="MainContentSubheader">
                Showing all accounts under your profile with their balances
              </span>
            </div>
            <div className="ContentSpace">
              <Toolbar style={{ float: "right" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "420px",
                  }}
                >
                  <button
                    style={{
                      backgroundColor: "rgba(0, 42, 209, 0.1)",
                      width: "200px",
                    }}
                    className="ContentWhiteButton"
                  >
                    <SettingsIcon style={{ color: "#002ad1" }} />
                    <span>Settings</span>
                  </button>
                  <button
                    style={{ backgroundColor: "#002ad1", width: "200px" }}
                    className="ContentWhiteButton"
                  >
                    <ArrowRightAltIcon style={{ color: "white" }} />
                    <span>Send Money</span>
                  </button>
                </div>
              </Toolbar>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={4} >
                  <Paper style={paperStyle}>

                  </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4} >
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4} >
                  </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
