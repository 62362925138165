import React, { Component } from "react";
import GaugeChart from "react-gauge-chart";
import '../../Styles/gauge-chart.css'

class Gauge extends Component {




  render() {

    let value = isNaN(this.props.speed) ? 0 : this.props.speed / 100;
    return (
      <GaugeChart
        id="gauge-chart"
        style={{ width: "200px", height: "190px !important", fontSize: '15px' }}
        nrOfLevels={12}
        colors={["#F05050", "#FFFFFF"]}
        percent={value}
        needleColor="#FFFFFF"
        needleBaseColor="#FFFFFF"
        textColor="#000000"
        formatTextValue={(value) => value + ''}
        arcWidth={0.1}
      />
    );
  }
}

export default Gauge;