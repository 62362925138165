import React, { Component } from "react";
import {
  Grid,

  ThemeProvider,
  withStyles
} from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles'
import Pagination from "material-ui-flat-pagination";

const stylesShowroom = theme => ({
  paper: {
    background: "none",
    width: "100%",
    minHeight: "1614px",
    marginBottom: "20px"
  }
});

const themeTable = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  overrides: {
    MuiFlatPageButton: {
      rootCurrent: {
        height: "30px",
        width: "30px",
        fontFamily: "inherit",
        marginLeft: "10px",
        backgroundColor: "#FFFFFF",
        border: "solid 1px #002ad1",
        boxShadow: "0px 4px 7px rgba(0, 0, 0, 0.05)"
      },
      rootStandard: {
        height: "30px",
        width: "30px",
        fontFamily: "inherit",
        marginLeft: "10px",
        backgroundColor: "#FFFFFF",
        border: "solid 1px #f1f1f1",
        boxShadow: "0 4px 7px 0 rgba(0, 0, 0, 0.01)"
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none"
      }
    },
    MuiButton: {
      root: {
        color: "#000000"
      },
      textSecondary: {
        color: "#000000 !important",
        "&:hover": {
          backgroundColor: "#E5E5E5"
        },
        "&:focus": {
          backgroundColor: "#ffffff"
        }
      },
      textPrimary: {
        color: "#1A1A1A"
      }
    }
  }
});

const rowsPerPage = 3;

class ShowroomCar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      page: 0
    };
  }
  CapitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  handleClick(offset) {
    this.setState({ offset });
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  render() {
    const { classes } = this.props;
    var availableCars = this.props.availableCars;
    const rows = [...Array(Math.ceil(availableCars.length / 3))];
    const carRows = rows.map((row, idx) =>
      availableCars.slice(idx * 3, idx * 3 + 3)
    );
    var CurrencyFormat = require("react-currency-format");
    var rate = this.props.rate;

    return (
      <div className={classes.paper}>
        <ThemeProvider theme={themeTable}>
          <div className={classes.paper}>
            {(rowsPerPage > 0
              ? carRows.slice(
                this.state.offset,
                Number((this.state.offset / rowsPerPage).toFixed(0)) *
                rowsPerPage +
                rowsPerPage
              )
              : carRows
            ).map((row, idx) => (
              <Grid container spacing={4} key={idx}>
                {row.map((car, index) => (
                  <Grid item xs={12} sm={12} md={12} lg={4} key={car.Id}>
                    <div
                      style={{ cursor: "pointer" }}
                    // onClick={() => this.props.handleSelectedCar(car.Id, 1)}
                    >
                      <div
                        className={
                          car.UsedType === "Locally Used"
                            ? "UseStatus Local"
                            : "UseStatus Foreign"
                        }
                      >
                        {this.CapitalizeFirstLetter(car.UsedType)}
                      </div>
                      <div className="ShowroomCarCard">
                        <div
                          className="car"
                          style={{
                            background: `url(${car.MainImage})`
                          }}
                        ></div>
                        <img
                          src="/icon/sale.svg"
                          alt="sale"
                          style={{ marginLeft: "20px", marginTop: "12px" }}
                        />
                        <div className="ShowroomCar">
                          <div>
                            <span className="LandingPageBlack">
                              {car.VehicleName} {car.Year}
                            </span>
                          </div>
                          <div style={{ display: "flex", marginTop: "20px" }}>
                            <div style={{ width: "50%" }}>
                              <div className="ShowroomCarDetails">
                                <img
                                  src="/icon/calendar.svg"
                                  alt="year"
                                  style={{ marginRight: "10px" }}
                                />
                                <span className="TrueBlack">{car.Year}</span>
                              </div>
                              <div className="ShowroomCarDetails">
                                <img
                                  src="/icon/color.svg"
                                  alt="color"
                                  style={{ marginRight: "10px" }}
                                />
                                <span className="TrueBlack">{car.Color}</span>
                              </div>
                              <div className="ShowroomCarDetails">
                                <img
                                  src="/icon/speed.svg"
                                  alt="speed"
                                  style={{ marginRight: "10px" }}
                                />
                                <span className="TrueBlack">{car.Year}</span>
                              </div>
                            </div>
                            <div style={{ width: "50%" }}>
                              <div className="ShowroomCarDetails">
                                <img
                                  src="/icon/gas.svg"
                                  alt="fuel"
                                  style={{ marginRight: "10px" }}
                                />
                                <span className="TrueBlack">
                                  {this.CapitalizeFirstLetter(car.FuelType)}
                                </span>
                              </div>
                              <div className="ShowroomCarDetails">
                                <img
                                  src="/icon/transmission.svg"
                                  alt="trans"
                                  style={{ marginRight: "10px" }}
                                />
                                <span className="TrueBlack">
                                  {this.CapitalizeFirstLetter(car.Transmission)}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div style={{ textAlign: "right", margin: "20px 0" }}>
                            <span
                              className="LandingPageBlack"
                              style={{ fontWeight: "900" }}
                            >
                              USD{" "}
                              <CurrencyFormat
                                value={parseFloat(car.CarPrice).toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </span>
                            <br />
                            <span
                              className="GreyText"
                              style={{ fontSize: "13px", fontWeight: "900" }}
                            >
                              KES{" "}
                              <CurrencyFormat
                                value={parseFloat(car.CarPrice * rate).toFixed(
                                  2
                                )}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </span>
                          </div>
                          {/* <button
                            className="SignUpFormsSubmit"
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.props.handleSelectedCar(car.Id, 2);
                            }}
                          >
                            Add deal to cart
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            ))}
          </div>
          <div style={{ textAlign: "center" }}>
            <Pagination
              disableFocusRipple={true}
              disableRipple={true}
              limit={rowsPerPage}
              offset={this.state.offset}
              total={carRows.length}
              onClick={(e, offset) => this.handleClick(offset)}
            />
          </div>
        </ThemeProvider>
      </div>
    );
  }
}

export default withStyles(stylesShowroom)(ShowroomCar);
