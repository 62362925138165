import React, { Component, useEffect, useState } from "react";
import NoData from '../SharedComponents/NoData';
import { useSelector } from "react-redux";
import { differenceInSeconds } from "date-fns";
class FleetTable extends Component {
  render() {

    return (
      <div style={{ maxHeight: "501px", overflowY: "auto" }}>
        {this.props.fleet[0] !== undefined ? (
          <>
            {this.props.fleet.map((step, index) => <FButton key={`assetIndex-${index}`} step={step} index={index} selected={this.props.selected} handleSelected={this.props.handleSelected} />)}
          </>
        ) : (
          <NoData />
        )}

      </div>
    );
  }
}

export default FleetTable;




const FButton = (props) => {

  const CurrentAssetsMap = useSelector((state) => state.AssetsMap[props.step.ImeiNumber]);

  const [dataStep, setData] = useState(props.step);

  useEffect(e => {

    if (CurrentAssetsMap)
      setData(CurrentAssetsMap);

  }, [CurrentAssetsMap, CurrentAssetsMap?.updateKey])

  return (<button
    className={
      dataStep.Id === props.selected.Id
        ? "FleetTableRowsSelected"
        : "FleetTableRows"
    }
    key={props.index}
    onClick={() => props.handleSelected(dataStep)}
  >
    <div>
      <div>
        <span className="BlackText">
          {dataStep.Make} {dataStep.Model}
        </span>
      </div>
      <div style={{ marginTop: "5px" }}>
        <span className="GreyText">{dataStep.NumberPlate}</span>
      </div>
      <div style={{ marginTop: "5px" }}>
        <span className="GreyText">{dataStep.vehicleDetails.Location}</span>
      </div>
    </div>


    <div>
      <div
        className={
          dataStep.vehicleDetails.vstatus === "Moving"
            ? "InProgress"
            : dataStep.vehicleDetails.vstatus === "Idling"
              ? "Idle"
              : "Completed"
        }
      >
        {dataStep.vehicleDetails.vstatus}
      </div>
      <div className="timeDuration"> {
        (() => {

          let startdate = new Date(dataStep.vehicleDetails.Time);

          let enddate = new Date()

          const seconds = differenceInSeconds(
            enddate,
            startdate

          )

          return GetStringTimeSpan(seconds)

        })()
      } </div>

    </div>

  </button>)

}


function GetStringTimeSpan(seconds) {



  if (seconds < 60)
    return "just now"


  let res = "";

  let days = (seconds / 86400)

  if (Math.floor(days) >= 1) {

    seconds -= (Math.floor(days) * 86400);

    res = `${Math.floor(days)}d `
  }

  let hours = (seconds / 3600)

  if (Math.floor(hours) >= 1) {

    seconds -= (Math.floor(hours) * 3600);

    res += `${Math.floor(hours)}h `
  }


  let minutes = (seconds / 60)

  if (Math.floor(minutes) >= 1) {

    seconds -= (Math.floor(minutes) * 60);

    res += `${Math.floor(minutes)}m `
  }



  res += `${Math.floor(seconds)}s ago`

  return res
}