import React, { Component } from "react";
import Loader from "../../Components/SharedComponents/Loader";
import TopBar from "../../Components/SharedComponents/TopBar";
import Menu from "../../Components/SharedComponents/Menu";
import { Grid, withStyles, Paper } from "@material-ui/core";
import AccountBalances from "../../Components/Dashboard/AccountBalances";
import QuickActions from "../../Components/Dashboard/QuickActions";
import Filter from "../../Components/SharedComponents/Filter";
import Search from "../../Components/SharedComponents/Search";
import PaymentsTable from "../../Components/Payments/PaymentsTable";
import TopUp from "../../Components/Payments/TopUp";
import { withRouter } from "react-router-dom";
import SendMoney from "../../Components/Payments/SendMoney";
import SendMoneyMpesa from "../../Components/Payments/SendMoneyMpesa";
//import CashOut from "../../Components/InvestmentAccount/CashOut";
import { getRequest } from "../../Services/FetchFunctions";
import CashOutMain from "../../Components/Payments/CashOutMain";


const styles = (theme) => ({
  paper: {
    boxShadow: "none",
    borderRadius: "5px",
    border: "none",
    backgroundColor: "rgba(255, 255, 255, 0.63)",
  },
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
});

class Payments extends Component {
  constructor() {
    super();
    this.state = {
      view: 0,
      accounts: [],
      otherAccounts: [
        {
          AccountCurrency: "KES",
          AccountBalance: "0.0",
          AccountType: "MAIN",
          AccountNumber: "1624568967941325",
          AccountName: "Default User"
        },
        {
          AccountCurrency: "KES",
          AccountBalance: "0.0",
          AccountType: "INVESTMENT",
          AccountNumber: "1624568967941325",
          AccountName: "Default User"
        }
      ],
      otherpayments: [
        {
          type: "Deposit to wallet",
          id: "ADjsd1496",
          amount: 2000,
          currency: "KES",
          date: "Mar 20 08:15PM",
          status: "Successful",
        },
        {
          type: "Deposit to wallet",
          id: "ADjsd1496",
          amount: 2000,
          currency: "KES",
          date: "Mar 20 08:15PM",
          status: "Successful",
        },

      ],
      payments: [],
      investAcc: [],
      mainAcc: [],
      incomeAcc: [],
      searchItem: "",
      startDate: new Date(),
      endDate: new Date(),
      transactionType: "none",
      transactionID: "",
      username: "",
      clientdata: [],
      clientPhone: ''
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleView = this.handleView.bind(this);
  }

  componentDidMount() {
    var view = this.props.history.location.view
      ? this.props.history.location.view
      : 0;
    this.handleView(view);
    this.getDets();
    var endpoint = "client/account/get-transactions";
    getRequest(endpoint)
      .then((resp) => {
        //console.log(resp.data.List);
        this.setState({ payments: resp.data.List });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getDets() {
    var url = "client/get-details";
    getRequest(url)
      .then((resp) => {

        var accounts = resp.data.accountDetails;
        var investAccount = accounts.filter(
          (account) => account.AccountType === "INVESTMENT"
        );
        var mainAccount = accounts.filter(
          (account) => account.AccountType === "MAIN"
        );
        let incomeAccount = accounts.filter(
          (account) => account.AccountType === "INCOME"
        );
        var nameUser = resp.data.firstName + " " + resp.data.lastName;
        this.setState({
          accounts: accounts,
          investAcc: investAccount,
          mainAcc: mainAccount,
          incomeAcc: incomeAccount,
          username: nameUser,
          clientPhone: resp.data.phoneNumber,
          clientdata: resp.data
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleSearch(searchItem) {
    this.setState({ searchItem: searchItem });
  }

  handleFilter(startDate, endDate, transactionType, transactionID) {
    this.setState({
      startDate: startDate,
      endDate: endDate,
      transactionType: transactionType,
      transactionID: transactionID,
    });
  }

  handleView(view) {
    this.setState({ view: view });
  }

  handleDisplay(view) {
    const { classes } = this.props;
    var payments = this.state.payments.filter((entry) =>
      Object.values(entry).some(
        (val) =>
          typeof val === "string" &&
          val.toLowerCase().includes(this.state.searchItem.toLowerCase())
      )
    );
    switch (view) {
      case 0:
        var account = this.state.mainAcc[0];
        var accountInv = this.state.investAcc[0];
        let incomeAcc = this.state.incomeAcc[0];
        let incomeAccountBalance = '0.00';
        var investment, mainAccount;
        if (accountInv !== undefined || incomeAcc !== undefined) {
          investment = accountInv;
          mainAccount = account.AccountBalance;
          if (account !== undefined) {
            mainAccount = account.AccountBalance;
            incomeAccountBalance = incomeAcc?.AccountBalance;
          }

          var username = this.state.username;
        } else {
          investment = this.state.otherAccounts
        }
        return (
          (
            <div className="Content">
              <div className="ContentHeader">
                <span className="MainContentHeader Bold">Payments</span>
                <br />
                <span className="MainContentSubheader">
                  Showing wallet balances and recent payments. From here you can
                  be able to cash-out or top-up your wallet for investments
                </span>
              </div>
              <div className="ContentSpace">
                <Grid container spacing={2}>
                  <Grid className="accountBalance" item xs={12} sm={12} md={12} lg={7}>
                    <Paper
                      className={classes.paper}
                    //style={{ height: "164px" }}
                    >
                      <AccountBalances accountInv={investment} income={incomeAccountBalance} accountMain={mainAccount} name={username} />{" "}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={5}>
                    <Paper
                      className={classes.paper}
                      style={{ height: "164px" }}
                    >
                      <QuickActions
                        payments={true}
                        handleView={this.handleView}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Paper
                      className={classes.paper}
                      style={{ minHeight: "740px", marginTop: "40px" }}
                    >
                      <div className="DisplayFlexB">
                        <div
                          style={{
                            marginRight: "15px",
                            marginBottom: "20px",
                          }}
                        >
                          <Filter
                            handleFilter={this.handleFilter}
                            payments={true}
                          />
                        </div>
                        <Search
                          searchItem={this.state.searchItem}
                          placeholder={"Search by transaction ID or type"}
                          handleSearch={this.handleSearch}
                        />
                      </div>
                      <div>
                        <PaymentsTable payments={payments} />
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            </div>
          )
        );
      case 1:
        return <TopUp data={this.state.clientdata} handleView={this.handleView} />;
      case 2:
        return <SendMoney data={this.state.clientdata} handleView={this.handleView} />;
      case 3:
        return <SendMoneyMpesa phone={this.state.clientPhone} accounts={this.state.accounts} handleView={this.handleView} />;
      case 4:
        return <CashOutMain phone={this.state.clientPhone} accounts={this.state.accounts} handleView={this.handleView} />;
      default:
        return "Unknown view";
    }
  }

  render() {

    return (
      <>
        {this.state.isLoading ? (
          <Loader text="Preparing your view" />
        ) : (
          <div className="ContainerApp">
            <TopBar />
            <div className="MainBody">
              <div className="Menu">
                <Menu />
              </div>
              {this.handleDisplay(this.state.view)}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(withStyles(styles)(Payments));
