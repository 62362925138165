import React, { useEffect, useState, useCallback, useRef } from "react";
import Loader from "../../Components/SharedComponents/Loader";
import TopBar from "../../Components/SharedComponents/TopBar";
import Menu from "../../Components/SharedComponents/Menu";
import { Grid, withStyles, Paper } from "@material-ui/core";
import FleetTable from "../../Components/FleetManagement/FleetTable";
import Search from "../../Components/SharedComponents/Search";
import FleetMoreDetails from "../../Components/FleetManagement/FleetMoreDetails";
import VehicleDetails from "../../Components/FleetManagement/VehicleDetails";
import { getRequest } from "../../Services/FetchFunctions";
//import GoogleMap from "../../Components/FleetManagement/GoogleMap";
import { Stomp } from '@stomp/stompjs'
import SockJS from 'sockjs-client'
import { useSelector, useDispatch } from "react-redux";
import { AddMultipleAssets, UpdateVehicleDetailsMap, UpdateIntervalReminder } from '../../redux/ReduxSlice';
import { useHistory } from "react-router-dom";


const styles = (theme) => ({
  paper: {
    boxShadow: "none",
    borderRadius: "5px",
    border: "solid 1px transparent",
    backgroundColor: "#ffffff",
    minHeight: "100vh",
  },
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
});




class FleetManagement extends React.Component {


  constructor() {
    super();
    this.state = {
      wsState: {
        username: "",
        receiberName: "",
        connected: false,
        message: ""
      },
      // vehicleDets: [],
      vehicleStatus: [],
      moreDetails: {
        Battery: 9,
        Time: "16 May 2021 16:40",
        Odometer: "114000 Km",
        Latitude: "-1.286389",
        Longitude: "36.817223",
        Speed: "0 km/h",
        vstatus: "Parked",
        Altitude: "60",
        Location: "Kilimani Building",
        FuelLevel: "3.0"
      },
      searchItem: "",
      selected: {},
      viewMore: true,
      isLoading: false,
      cardID: "",
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSelected = this.handleSelected.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);

  }

  componentDidMount() {
    this.handleFetchVehicles();
  }


  componentDidUpdate(prevProps) {

    if (Object.keys(this.state.selected).length === 0) {

      let details = this.props.vehicleDetails;

      if (details[0] !== undefined) {
        this.handleSelected(details[0]);
      }

    }
  }

  handleSearch(searchItem) {
    this.setState({ searchItem: searchItem });
  }

  handleFetchVehicles() {

    this.setState({ isLoading: true });

    this.setState({ isLoading: false });



  }

  handleOpen(carData) {
    this.setState({ selected: carData, viewMore: true });
    this.handleSelected(carData);
  }

  handleSelected(carData) {

    var vehID = carData.Id;

    var vehicleURL = `client/vehicle/status/${vehID}`;

    this.setState({ selected: carData })

    getRequest(vehicleURL).then((resp) => {
      setTimeout(e => this.setState({ moreDetails: resp.data.Data.vehicleDetails }), 1000);
    });
  }

  handleClose() {
    this.setState({ viewMore: false });
  }

  render() {

    const { classes } = this.props;

    var vehicleDets = this.props.vehicleDetails;


    var fleet2 = vehicleDets.filter((entry) =>
      Object.values(entry).some(
        (val) =>
          typeof val === "string" &&
          val.toLowerCase().includes(this.state.searchItem.toLowerCase())
      )
    );

    var selected = this.state.selected;

    var theselected;

    if (selected !== undefined) {
      theselected = selected;
    }
    else {

      theselected = vehicleDets[0];

      console.log({ selected })
    }



    let carDetails = Object.keys(this.state.selected).length === 0 ? fleet2[0] : this.state.selected;

    let moreDetails = Object.keys(this.state.moreDetails).length === 0 ? [] : this.state.moreDetails;


    return (
      <div className="FleetManagement">
        <div className="ContainerApp">
          <TopBar />
          <div className="MainBody">
            <div className="Menu">
              <Menu />
            </div>
            <div className="ContentFull">
              {vehicleDets[0] === undefined ?
                (
                  <Loader text="Preparing your view" />
                ) : (
                  <>

                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={4}>
                        <Paper className={classes.paper} style={{ height: "100%" }}>
                          <div className="FleetManagementTableCard">
                            <div className="ContentHeader">
                              <span className="MainContentHeader Bold">
                                Fleet management
                              </span>
                              <br />
                              <span className="MainContentSubheader">
                                Select on a vehicle below to view status and
                                location
                              </span>
                            </div>
                            <div>
                              <div className="FilterDiv">
                                <img
                                  src="/icon/filter.svg"
                                  alt="filter"
                                  style={{ marginRight: "10px" }}
                                />
                                <span className="BlackText">Filters</span>
                              </div>
                              <div
                                style={{ marginTop: "10px", marginBottom: "30px" }}
                              >
                                <Search
                                  searchItem={this.state.searchItem}
                                  placeholder={
                                    "Search by vehicle name or number plate"
                                  }
                                  handleSearch={this.handleSearch}
                                />
                              </div>
                              <FleetTable
                                fleet={fleet2}
                                handleSelected={this.handleSelected}
                                selected={
                                  theselected.length === 0 ? fleet2[0] : theselected
                                }
                                handleOpen={this.handleOpen}
                              />
                            </div>
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={8}>
                        {this.state.viewMore ? (
                          <VehicleDetails
                            carDetails={carDetails}
                            moreDetails={moreDetails}
                            handleClose={this.handleClose}
                          />

                        ) : (
                          <Paper
                            className={classes.paper}
                            style={{ height: "100%" }}
                          >
                            <FleetMoreDetails
                              carDetails={
                                theselected.length === 0 ? fleet2[0] : theselected
                              }
                              moreDetails={
                                Object.keys(this.state.moreDetails).length === 0
                                  ? []
                                  : this.state.moreDetails
                              }
                              handleOpen={this.handleOpen}
                            />

                          </Paper>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
            </div>
          </div>
        </div>

      </div>
    );
  }
}

const FleetManagementWithStyles = withStyles(styles)(FleetManagement);


const FleetInitPage = () => {


  var StompClient = useRef(null);

  const history = useHistory();

  const dispatch = useDispatch();

  const AppClient = useSelector((state) => state.Client);

  const [staticAssetsList, setStaticAssetList] = useState([])

  const [connected, setConnected] = useState(false);

  const [ClientConnectObj, setClientConnectObj] = useState(null);

  const subscriptionStr = `/topic/client-stage-vehicle-asset/${AppClient.uniqueId}`;


  const MultipleassetUpdate = useCallback((payloadRaw) => {


    var vehicleDet = JSON.parse(payloadRaw.body);

    dispatch(UpdateVehicleDetailsMap(vehicleDet))
  }, [dispatch]);




  const onError = useCallback((error) => {
    console.log(error);
  }, []);


  const onConnected = useCallback(() => {

    setConnected(true)

    StompClient.current.send("/app/start-sending", {}, null);

    let res = StompClient.current.subscribe(subscriptionStr, MultipleassetUpdate);

    setClientConnectObj(res);


  }, [MultipleassetUpdate, subscriptionStr]);


  const RegisterClientCallBack = useCallback(() => {

    //var url = "http://localhost:8080/telematics/ws";

    const url = "https://telematics.javaselfdrive.com/telematics/ws";

    StompClient.current = Stomp.over(function () {

      return new SockJS(url);
    });


    StompClient.current.connect({}, onConnected, onError);

    StompClient.current.reconnect_delay = 5000;


  }, [onConnected, onError]);





  useEffect(e => {

    GetAssets().then((resp) => {

      dispatch(AddMultipleAssets(resp));

      setStaticAssetList(resp);

    })
      .catch(error => {
        if (error.response.status === 401) {
          history.push("/login")
        }


      });

  }, [dispatch, history])




  useEffect(e => {

    if (!connected)
      RegisterClientCallBack();

    var interval = setInterval(e => dispatch(UpdateIntervalReminder(Math.random())), 1000 * 60)

    return () => {
      clearInterval(interval);

      if (ClientConnectObj) {

        ClientConnectObj.unsubscribe()

        StompClient.current.reconnect_delay = 0;
      }

    }


  }, [connected, RegisterClientCallBack, dispatch, ClientConnectObj])




  return <FleetManagementWithStyles vehicleDetails={staticAssetsList} />
}

export default FleetInitPage;



const GetAssets = async () => {

  return getRequest("client/vehicle/list")
    .then((resp) => {

      return resp.data.List.map(p => ({ ...p, ImeiNumber: p.vehicleDetails.ImeiNumber }))
    });


}


