import axios from "axios";
//const BASE_URL ="http://206.189.105.47:8090/api/v1/";
//const BASE_URL = "https://stage-backend.javaselfdrive.com/api/v1/";
const BASE_URL = "https://stage-backend.javaselfdrive.com/api/v1/";



function getRequest(endpoint) {

  const token = JSON.parse(localStorage.getItem("id_tkn"));
  if (token) {
    var options = {
      headers: { Authorization: "Bearer " + token },
    };
    return axios.get(BASE_URL + endpoint, options);
  } else {
    return axios.get(BASE_URL + endpoint);
  }
}

function post_request(endpoint, payload) {

  const token = JSON.parse(localStorage.getItem("id_tkn"));


  if (token) {
    var options = {
      headers: { Authorization: "Bearer " + token },
    };

    return axios.post(BASE_URL + endpoint, payload, options);
  } else {
    return axios.post(BASE_URL + endpoint, payload);
  }
}

export { getRequest, post_request, BASE_URL };
