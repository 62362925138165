import React, { Component } from "react";
import Background from "../../Styles/icon/Accountbalances.svg";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

class AccountBalances extends Component {
  render() {
    var account = this.props.accountInv;
    var accountMain = this.props.accountMain;
    let incomeAcc = this.props.income
    
    let CurrencyFormat = require("react-currency-format");

    return (
      <div
        className="Cards AccountBalances"
        style={{ background: `url(${Background})`, padding:'18px 25px'}}
      >
        
        <div className="DisplayFlex1" style={{marginBottom:'10px'}}>
        <div>
          <span style={{ textTransform: "uppercase" }} className="WhiteText">
            {this.props.name} Account
          </span>
        </div>
          <div>
            <span className="WhiteText" style={{ fontWeight: "normal" }}>
              A/c ID: {account.AccountNumber}
            </span>
          </div>
          {/* <img src="/icon/refresh.svg" alt="refresh" /> */}
        </div>
       <Table>
         <TableRow>
           <TableCell style={{borderBottom:'none'}}>
           <span
      className="WhiteText"
      style={{ color: " rgba(245, 249, 254, 0.63)" }}
    >
      Main Account Balance
    </span>
           </TableCell>
           <TableCell style={{borderBottom:'none'}}>
           <span className="WhiteText">
      {account.AccountCurrency}
      <CurrencyFormat
              value={parseFloat(accountMain).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />
    </span>
           </TableCell>
         </TableRow>
         <TableRow>
           <TableCell style={{borderBottom:'none',padding:'10px'}}>
           <span
                className="WhiteText"
                style={{ color: " rgba(245, 249, 254, 0.63)" }}
              >
                Investment Account Balance
              </span>
           </TableCell>
           <TableCell style={{borderBottom:'none',padding:'10px'}}>
           <span className="WhiteText">
                {" "}
                {account.AccountCurrency} {" "}
                <CurrencyFormat
                        value={parseFloat(account.AccountBalance).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
               
              </span>
           </TableCell>
         </TableRow>
         <TableRow>
           <TableCell style={{borderBottom:'none',padding:'10px'}}>
           <span
                className="WhiteText"
                style={{ color: " rgba(245, 249, 254, 0.63)" }}
              >
                Income Account Balance
              </span>
           </TableCell>
           <TableCell style={{borderBottom:'none',padding:'10px'}}>
           <span className="WhiteText">
               KES {" "}
                <CurrencyFormat
                        value={parseFloat(incomeAcc).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
               
              </span>
           </TableCell>
         </TableRow>
       </Table>
      
        
      </div>
    );
  }
}

export default AccountBalances;
