import React, { Component } from "react";
import { Grid, Avatar } from "@material-ui/core";

class Benefits extends Component {
  constructor() {
    super();
    this.state = {
      benefits: [
        {
          title: "Transparent reporting",
          text: "Get detailed transaction insights of your portfolio performance, updated daily on the portal.",
          src: "./icon/transparent.png",
        },
        {
          title: "Secure",
          text: "Cars are fitted with high tech security equipments which utilize satellites to track & secure your car.",
          src: "./icon/secure.png",
        },
        {
          title: "24/7 Support",
          text: "A committed team of support staff to assist, and give you the best experience, as you grow your investment.",
          src: "./icon/chatus.png",
        },
        {
          title: "Attractive returns",
          text: "We value your time and go extra mile to maximize your returns. Est. Y1 returns of up to 80% +.",
          src: "./icon/attractive.svg",
        },
      ],
    };
  }
  render() {
    return (
      <div className="Benefits LandingPagePadding">
        <div style={{ width: "46%", marginBottom: "40px" }}>
          <span className="LandingPageHeader1">
            Benefits of working with us
          </span>
          <span
            style={{
              color: "rgba(37, 37, 37, 0.7)",
              fontSize: "22px",
              marginTop: "15px",
            }}
            className="subHeading"
          >
            As you invest with us, we make it easier and convinent to build
            wealth and grow your portfolio.
          </span>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div>
              <img className="pinkGirl"
                src="./icon/work_with_us.png"
                alt="workWithUs"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {this.state.benefits.map((benefit, index) => (
              <div key={index} style={{ display: "flex" }}>
                <Avatar
                  style={{
                    background: "#f4f5f7",
                    width: "70px",
                    height: "70px",
                  }}
                >
                  <img src={benefit.src} alt="icon" />
                </Avatar>
                <div style={{marginLeft:'10px',marginBottom:'50px'}}>
                  <span className="BenefitsListHeading">{benefit.title}</span>
                  <p className="BenefitsListText">{benefit.text}</p>
                </div>
              </div>
            ))}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Benefits;
