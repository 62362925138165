import React from "react";
import Odometer from 'react-odometerjs';
import '../../odometer-theme-car.css'


function OdometerM(props) {

  return <div style={{ fontSize: 25, marginTop: 20, width: "240px" }}>
    <Odometer value={props.value} format="(,ddd).dd" />
  </div>;

}

export default OdometerM;