import React, { Component } from "react";
import {
  withStyles,

  Modal,
  Backdrop,
  MuiThemeProvider,
  CircularProgress
} from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles'
import { validateEmail } from "../../Utilities/SharedFunctions";
import { withRouter } from "react-router-dom";
import { post_request } from "../../Services/FetchFunctions";
import CustomSnackbar from "../../Components/SharedComponents/CustomSnackbar";

const stylesModal = theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    background: "none",
    border: "none",
    borderRadius: "5px",
    boxShadow: "inherit"
  },
  container: {
    minHeight: 450,
    overflowX: "hidden"
  }
});

const themeModal = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(37, 43, 51, 0.5",
        backdropFilter: "blur(2px)"
      }
    }
  }
});

class ModalLogin extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      completed: 5,
      buffer: 10,
      title: "",
      message: "",
      validEmail: false,
      success: false,
      isLoading: false,
      responseStatus: "",
      snackbaropen: false,
      snackbarmsg: "",
      snackbartxt: ""
    };

    this.handleFormInput = this.handleFormInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleFormInput(event) {
    this.setState({ [event.target.id]: event.target.value });
    if (event.target.id === "email") {
      this.setState({ validEmail: validateEmail(event.target.value) });
    }
  }

  handleSubmit = async () => {
    this.setState({ isLoading: true });
    var payload = {
      username: this.state.email,
      password: this.state.password,
      type: "2"
    };
    const url = `authenticate`;
    post_request(url, payload)
      .then(resp => {
        this.setState({
          isLoading: false
        });
        localStorage.setItem("userData", JSON.stringify(resp.data));
        this.props.history.push("/Dashboard");
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          snackbaropen: true,
          snackbarmsg: "Login Failed",
          responseStatus: "failed",
          snackbartxt: err.response.data.message
        });
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <CustomSnackbar
          hideAlert={this.snackbarClose}
          showSnack={this.state.snackbaropen}
          hideSnack={this.snackbarClose}
          response={this.state.responseStatus}
          title={this.state.snackbarmsg}
          messagetxt={this.state.snackbartxt}
        />
        <MuiThemeProvider theme={themeModal}>
          <Modal
            className={classes.modal}
            open={this.props.setOpen}
            onClose={() => this.props.handleOpen()}
            closeAfterTransition
            BackdropComponent={Backdrop}
          >
            <div className={classes.paper}>
              <div className="ModalPaper">
                <div className="ModalHeader" style={{ padding: "0px" }}>
                  <div>
                    <button
                      className="BackButton"
                      style={{ float: "right" }}
                      onClick={() => this.props.handleOpen()}
                    >
                      <img src="./icon/close.svg" alt="x" />
                    </button>
                  </div>
                  <div
                    style={{
                      alignItems: "center",
                      display: "inline-block",
                      marginTop: "50px"
                    }}
                  >
                    <span
                      className="MainContentHeader"
                      style={{ fontSize: "20px" }}
                    >
                      Login{" "}
                    </span>
                    <br />
                    <span className="MainContentSubheader">
                      Login to your account to complete checkout
                    </span>
                  </div>
                </div>
                <div className="ProfileForm" style={{ marginTop: "30px" }}>
                  <label>Email address</label>
                  <input
                    id="email"
                    type="email"
                    value={this.state.email}
                    style={
                      this.state.email !== "" && this.state.validEmail !== true
                        ? { border: "1px solid #F05050" }
                        : null
                    }
                    onChange={this.handleFormInput}
                    required
                  />
                  <label>Password</label>
                  <input
                    id="password"
                    type="password"
                    value={this.state.password}
                    onChange={this.handleFormInput}
                    className="SignUpFormsPassword"
                    required
                  />
                </div>
                <div className="ModalFooter">
                  <button
                    className={
                      !this.state.validEmail || this.state.password === ""
                        ? "SignUpFormsSubmitDisabled"
                        : "SignUpFormsSubmit"
                    }
                    disabled={
                      !this.state.validEmail || this.state.password === ""
                        ? true
                        : false
                    }
                    onClick={() => this.handleSubmit()}
                  >
                    {this.state.isLoading ? (
                      <CircularProgress style={{ color: "white" }} size={20} />
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "25px",
                    color: "rgba(37, 37, 37, 0.7)"
                  }}
                >
                  <button
                    className="RedirectButton"
                    onClick={() => this.props.history.push("/Reset")}
                  >
                    Reset password
                  </button>
                </div>
                <div
                  className="Bold"
                  style={{
                    textAlign: "center",
                    margin: "25px 0",
                    fontSize: "14px"
                  }}
                >
                  <span>Don’t have an account? </span>
                  <button
                    className="RedirectButton"
                    onClick={() => this.props.history.push("/Signup")}
                  >
                    Register
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withRouter(withStyles(stylesModal)(ModalLogin));
