import React, { Component } from "react";
import { Grid, withStyles, Paper } from "@material-ui/core";
import TopCar from "./TopCar";
import WeekSelect from "../Dashboard/WeekSelect";
import Graph from "../SharedComponents/GraphC";
import moment from "moment";
import Progress from "../Dashboard/Progress";
import CashOut from "./CashOut";
import { getRequest } from "../../Services/FetchFunctions";
import TopUp from "../Payments/TopUp";
import LockAccount from "./LockAccount";
import UnlockAccount from "./UnlockAccount";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
//import { Line } from "react-chartjs-2";

const styles = (theme) => ({
  paper: {
    boxShadow: "none",
    borderRadius: "5px",
    border: "solid 1px #f5f9fe",
    backgroundColor: "rgba(255, 255, 255, 0.63)",
    padding: "25px",
  },
  paper1: {
    boxShadow: "none",
    borderRadius: "5px",
    border: "solid 1px #f1f1f1",
    backgroundColor: "rgba(255, 255, 255, 0.63)",
  },
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
});

class Overview extends Component {
  constructor() {
    super();
    this.state = {
      topCar: [],
      investmentTotals: [
        {
          AccountCurrency: "KES",
          AccountBalance: 12000, // a/c balance
          AccountType: "INVESTMENT",
          totalProfit: 12000, // income wallet
          totalAssets: 425300, // wallet + investment kit
        },
      ],
      assetValue: 0.0,
      accounts: [],
      filteredAccount: [],
      accountBalance: "",
      investmentGrowth: 0,
      selectedPeriod: "W",
      investments: [],
      invest: [],
      view: 0,
      clientPhone: "",
      lockAccount: false,
      setOpen: false,
      setUnlockOpen: false,
      lockStatus: [
        {
          AccountCurrency: "KES",
          IsAccountLocked: "NO",
          AccountBalance: 50000,
          AccountType: "INVESTMENT",
          PerToTarget: 500,
          TargetAmount: 10000,
          AccountNumber: "1624270186003818"
        }
      ]
    };

    this.handleSelectedPeriod = this.handleSelectedPeriod.bind(this);
    this.handleView = this.handleView.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleUnlock = this.handleUnlock.bind(this);
  }
  componentDidMount() {
    this.getTopCar();
    this.getInvestments();
    this.getLockStatus();
    this.getTransactions();
  }

  handleSelectedPeriod(period) {
    this.setState({ selectedPeriod: period });
  }
  handleView(view) {
    this.setState({ view: view });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  getTopCar() {
    const url = "client/vehicle/list";
    getRequest(url)
      .then((resp) => {
        this.setState({
          topCar: resp.data.List,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getInvestments() {
    const url = "client/get-details";
    getRequest(url)
      .then((resp) => {
        var accounts = resp.data.accountDetails;
        var filteredAccount = accounts.filter(
          (account) => account.AccountType === "INVESTMENT"
        );
        this.setState({
          accounts: resp.data.List,
          filteredAccount: filteredAccount,
          clientPhone: resp.data.phoneNumber,
        });
        //console.log(filteredAccount);
        this.setState({
          filteredAccount: filteredAccount,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async getTransactions() {
    const url = "client/account/get-transactions";
    try {
      let response = await getRequest(url);
      console.log(response);
      let trans = response.data.List;
      let invest = trans.filter(
        (trans) => trans.AccountType === "INVESTMENT"
      );
      console.log(invest)
      this.setState({ investments: invest })
    } catch (error) {
      console.log(error)
    }
  }
  async getLockStatus() {
    const url = "client/account/get-investment-accounts";
    try {
      let response = await getRequest(url);
      //console.log(response)
      this.setState({ lockStatus: response.data.List[0], investmentGrowth: response.data.List[0].PerToTarget })
    } catch (error) {
      console.log(error)
    }
  }
  handleOpen() {
    this.setState({ setOpen: !this.state.setOpen });
  }
  handleUnlock() {
    this.setState({ setUnlockOpen: !this.state.setUnlockOpen });
  }

  render() {

    var account = this.state.filteredAccount[0];
    if (account !== undefined) {
      var curr = account.AccountCurrency;
      var balance = account.AccountBalance;
      var profit = 0;
    }
    //console.log(account);
    const { classes } = this.props;
    var CurrencyFormat = require("react-currency-format");
    var duration = this.state.selectedPeriod;
    var period = 0;
    var dateLabel = [];
    if (duration === "W") {
      period = 6;
    }

    for (var i = period; i >= 0; i--) {
      var newDate = moment().add(-i, "days").format("ddd DD");

      dateLabel.push(newDate);
    }
    //let amounts =  this.state.investments.map((inv)=> inv.Amount);
    //  let dates = this.state.investments.map((inv)=> inv.TransDate);
    // //console.log(amounts,dates);
    // const currency = "KES";
    // const data = (canvas) => {
    //   const ctx = canvas.getContext("2d");
    //   var gradient = ctx.createLinearGradient(0, 0, 0, 180);
    //   gradient.addColorStop(0, "rgba(0, 42, 209, 0.59)");
    //   gradient.addColorStop(0.5, "rgba(0, 42, 209, 0.31)");
    //   gradient.addColorStop(1, "rgba(255,255,255, 0)");
    //   return {
    //     labels: dateLabel,
    //     datasets: [
    //       {
    //         label: "Investment",
    //         data: 0,
    //         fill: true,
    //         backgroundColor: gradient,
    //         borderColor: "rgba(0, 42, 209)",
    //         lineTension: 0.4,
    //         pointBackgroundColor: "#ffffff",
    //       },
    //     ],
    //   };
    // };
    // const options = {
    //   animation: true,
    //   maintainAspectRatio: false,
    //   responsive: true,
    //   scales: {
    //     y: {
    //       grace: amounts.length > 0 ? "100" : null,
    //       beginAtZero: false,
    //       ticks: {
    //         autoSkip: false,
    //         stepSize: amounts.length > 0 ? 100 : 0,
    //         maxTicksLimit: 5,
    //         callback: function (value) {
    //           return (
    //             currency + ' ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    //           );
    //         },
    //       },
    //       grid: {
    //         color: "rgba(204, 204, 204, 0.07)",
    //         borderColor: "rgba(204, 204, 204, 0.07)",
    //       },
    //     },
    //     X: {
    //       grid: {
    //         color: "rgba(204, 204, 204, 0.07)",
    //         borderColor: "rgba(204, 204, 204, 0.07)",
    //       },
    //     },
    //   },
    //   plugins: {
    //     legend: {
    //       display: false,
    //     },
    //     title: {
    //       display: false,
    //     },
    //     tooltip: {
    //       callbacks: {
    //         labelColor: function (context) {
    //           return {
    //             borderColor: "none",
    //             backgroundColor: "#002AD1",
    //             borderWidth: 2,
    //             borderRadius: 2,
    //           };
    //         },
    //         labelTextColor: function (context) {
    //           return "#ffffff";
    //         },
    //       },
    //       backgroundColor: "rgba(0, 42, 209, 0.8)",
    //     },
    //   },
    // };
    return (
      <div>
        {this.state.view === 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Paper className={classes.paper} style={{ minHeight: "215px" }}>
                <div className="DisplayFlex1">
                  <div>
                    <span className="MainContentHeader Bold">
                      Total on investment account
                    </span>
                  </div>
                  <img src="/icon/refresh.svg" alt="refresh" />
                </div>
                <span className="MainContentSubheader">
                  Total value on investment wallet
                </span>
                <div style={{ marginTop: "35px", fontSize: "24px" }}>
                  <div>
                    <span style={{ color: "#2eb57e" }} className="Bold">
                      {curr}{" "}
                      <CurrencyFormat
                        value={parseFloat(balance).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </span>
                  </div>
                </div>
                <div className="DisplayFlex1">
                  <button
                    className="ContentWhiteButton"
                    style={{ width: "25%", padding: '0 20px' }}
                    onClick={() => {
                      this.handleView(2);
                    }}
                  >
                    <img src="/icon/walletTopup.svg" alt="wallet" />
                    <span>Top up </span>
                  </button>
                  <button
                    className={this.state.lockStatus.IsAccountLocked === "YES" ? "ContentWhiteButtonDisabled" : "ContentWhiteButton"}
                    style={{ width: "25%" }}
                    disabled={this.state.lockStatus.IsAccountLocked === "YES" ? true : false}
                    onClick={() => {
                      this.handleView(1);
                    }}
                  >

                    <SendOutlinedIcon style={{ color: 'rgba(37, 37, 37, 0.4)' }} />
                    <span>Cash out</span>
                  </button>

                  {this.state.lockStatus.IsAccountLocked === "YES" ? (
                    <>
                      <div className="DisplayFlexSpace" style={{
                        marginTop: "10px",
                        cursor: "pointer",
                        float: "right",
                        width: "150px",
                        backgroundColor: "rgba(245, 135, 48, 0.1)",
                        borderRadius: "5px",
                        height: "45px",
                        padding: "0 13px",
                      }}
                        onClick={() => this.handleUnlock()}
                      >
                        <LockIcon style={{ color: "#b55407", fontSize: '20px' }} />
                        <span
                          className="GreyText"
                          style={{ marginLeft: "5px", color: "#f58730" }}
                        >
                          Unlock account
                        </span>
                      </div>

                    </>
                  ) : (
                    <>
                      <div className="DisplayFlexSpace"
                        style={{
                          marginTop: "10px",
                          cursor: "pointer",
                          float: "right",
                          width: "150px",
                          backgroundColor: "rgba(245, 135, 48, 0.1)",
                          borderRadius: "5px",
                          height: "45px",
                          padding: "0 13px",
                        }}
                        onClick={() => this.handleOpen()}>
                        <LockOpenIcon style={{ color: "#b55407", fontSize: '20px' }} />
                        <span
                          className="GreyText"
                          style={{ marginLeft: "5px", color: "#f58730" }}
                        >
                          Lock account
                        </span>
                      </div>

                    </>
                  )}


                </div>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={4}>
              <Paper className={classes.paper} style={{ minHeight: "226px" }}>
                <span className="MainContentHeader Bold">Total profits </span>

                <div style={{ marginBottom: "35px", fontSize: "24px" }}>
                  <span style={{ color: "#2eb57e" }} className="Bold">
                    {curr}{" "}
                    <CurrencyFormat
                      value={parseFloat(profit).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </span>
                </div>
                <span className="MainContentHeader Bold">
                  Total asset value{" "}
                </span>
                <br />

                <div style={{ fontSize: "24px" }}>
                  <span style={{ color: "#2eb57e" }} className="Bold">
                    {curr}{" "}
                    <CurrencyFormat
                      value={parseFloat(this.state.assetValue).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </span>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="MiddleCards"
              >
                <Paper className={classes.paper} style={{ minHeight: "290px" }}>
                  <div className="DisplayFlex1">
                    <div>
                      <span className="MainContentHeader Bold">
                        Investment account trends
                      </span>
                    </div>
                    <div>
                      <WeekSelect
                        selectedPeriod={this.state.selectedPeriod}
                        handleSelectedPeriod={this.handleSelectedPeriod}
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: "25px" }}>
                    <Graph
                      height={180}
                      currency={curr}
                      data={this.state.invest}
                      label={dateLabel}
                      tooltipLabel="Investment"
                    />
                    {/* <Line data={data} options={options}/> */}
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper className={classes.paper} style={{ minHeight: "305px" }}>
                  <span className="MainContentHeader Bold">
                    Top car in your fleet
                  </span>
                  <br />
                  <TopCar topCar={this.state.topCar} />
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="MiddleCards"
              >
                <Paper className={classes.paper} style={{ minHeight: "414px" }}>
                  <span className="MainContentHeader Bold">
                    Portfolio growth
                  </span>
                  <br />
                  <span className="MainContentSubheader">
                    Showing percentage growth of your invesment to maturity{" "}
                  </span>
                  <div className="DisplayFlex" style={{ marginTop: "30px" }}>
                    <Progress
                      status={this.state.investmentGrowth}
                      height={110}
                    />
                    <div
                      className="DisplayFlex1"
                      style={{ height: "20px", alignItems: "center" }}
                    >
                      <div
                        className="StatusLegend"
                        style={
                          this.state.investmentGrowth > 50
                            ? { background: "#f58730" }
                            : null
                        }
                      ></div>
                      <div>
                        <span className="GreyText">Investment growth</span>
                      </div>
                    </div>
                  </div>
                  <div className="InvestmentMessage">
                    <img src="/icon/warning1.svg" alt="warning" />
                    <div>
                      <span className="GreyText">
                        {this.state.investmentGrowth === 0
                          ? "You haven’t started earning in your account. "
                          : "We advice you continue investing your money on the investment account. Once your account matures, we’ll notify you for re-investment options"}
                      </span>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper
                  className={classes.paper}
                  style={{ height: "175px", background: "#252b33" }}
                >
                  <div className="DisplayFlex1">
                    <div style={{ width: "69%" }}>
                      <span
                        className="WhiteText"
                        style={{ fontSize: "18px", lineHeight: "1.5em" }}
                      >
                        Grow your investment account
                      </span>
                    </div>
                    <img src="/icon/investment.svg" alt="investment" />
                  </div>
                  <button
                    className="OrangeButton"
                    style={{ marginTop: "30px" }}
                  >
                    Add new investment
                    <img src="./icon/add.svg" alt="add" />
                  </button>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        )}
        {this.state.view === 1 && (
          <CashOut
            phone={this.state.clientPhone}
            handleView={this.handleView}
          />
        )}
        {this.state.view === 2 && (
          <TopUp data={this.state.clientPhone} handleView={this.handleView} />
        )}
        <LockAccount
          account={account}
          handleOpen={this.handleOpen}
          setOpen={this.state.setOpen}
        />
        <UnlockAccount lockStatus={this.state.lockStatus} setUnlockOpen={this.state.setUnlockOpen} handleUnlock={this.handleUnlock} />
      </div>
    );
  }
}

export default withStyles(styles)(Overview);
