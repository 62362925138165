import React, { Component } from "react";
import LandingPageFooter from "../../Components/LandingPage/Footer";
import AppMenu from "../../Components/LandingPage/menu";
import { Grid } from "@material-ui/core";
import AOS from "aos";
import "aos/dist/aos.css";
import Benefits from "../../Components/LandingPage/Benefits";
import SearchIcon from "@material-ui/icons/Search";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import MoneyWork from "../../Components/LandingPage/MoneyWork";

class LandingPage extends Component {
  constructor() {
    super();
    this.state = {
      isChecked: false,
      howItWorks: [
        {
          title: "Select car",
          text: "Search from our list of vehicles of new and used cars",
          src: "./icon/01.svg",
        },
        {
          title: "Payment",
          text: "Pay full or partial investment amount during checkout",
          src: "./icon/03.svg",
        },
        {
          title: "Delivery",
          text: "Car will be purchased and added to your investment account",
          src: "./icon/05.svg",
        },
        {
          title: "Invest",
          text: "View and manage returns on your investment portfolio",
          src: "./icon/07.svg",
        },
      ],
      popularModels: [
        { model: "Toyota", src: "./icon/toyota.svg" },
        { model: "Audi", src: "./icon/image 3.svg" },
        { model: "Nissan", src: "./icon/image 4.svg" },
        { model: "Volkswagen", src: "./icon/image 5.svg" },
        { model: "Mercedes", src: "./icon/image 6.svg" },
        { model: "BMW", src: "./icon/image 8.svg" },
      ],
      models: [
        "Toyota",
        "Nissan",
        "Subaru",
        "Honda",
        "Mercedes Benz",
        "Audi",
        "Volkswagen",
        "BMW",
        "Mistubishi",
        "Lexus",
        "Mazda",
        "Landrover",
        "Ford",
        "Isuzu",
        "Chevrolet",
        "Chrysler",
        "Peugeot",
        "Volvo",
        "Hino",
      ],
      testimonials: [
        {
          name: "Floyd Miles",
          location: "Nairobi, Kenya",
          src: "./icon/Floyd.svg",
          testimony:
            "I bought my car with JavaSelfDrive, the process was smooth and hassle free. Adding the vehicle to the invesment kit was a delightful experience and I look forward to growing my invesment portfolio to greater numbers.",
        },
        {
          name: "Anita Doe",
          location: "Mombasa, Kenya",
          src: "./icon/Anita.svg",
          testimony:
            "I use JavaSelfDrive to manage my fleet and view account trends on my portfolio. Would definitely recommend geting an account.",
        },
        {
          name: "James Hartley",
          location: "Nakuru, Kenya",
          src: "./icon/James.svg",
          testimony:
            "Through JavaSelfDrive, I was able to purchase my first car after I choose to use the partial investment plan. The Onboarding process was easy and delightful. ",
        },
      ],
    };
  }

  componentDidMount() {
    this.setState({ isChecked: true });
    AOS.init({
      duration: 2000,
    });
  }

  render() {
    return (
      <div>
        <AppMenu />
        <div
          className="BuySell"
          style={{
            background: `url("./icon/landingPageBG.svg")`,
          }}
        >
          <div
            className="BuySellCar"
            style={{
              background: `url("./icon/hero-image.png")`,
            }}
          >
            <div className="BuySellDiv" data-aos="fade-up">
              <span className="LandingPageHeader">
                <span className="bolderBlue">Invest </span> with Confidence.
              </span>
              <span className="LandingPageSubHeader1">
                Become a car owner with as low as <b>50%</b> of the car's value
                and <b>get paid</b> each time your vehicle is hired.
              </span>
              <button
                className="SignUpFormsSubmit"
                style={{
                  width: "264px",
                  height: "56px",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <SearchIcon fontSize="small" />
                Explore investment deals
              </button>
              <div className="LandingHeaderBenefits">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} lg={4}>
                  <img src="./icon/check-circle.svg" alt="check" />
                <span style={{marginLeft:'10px'}}>Secure investing</span>
                
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                  
                <img src="./icon/check-circle.svg" alt="check" />
                <span style={{marginLeft:'10px'}}>Transparent reporting</span>
               
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                  
                <img src="./icon/check-circle.svg" alt="check" />
                <span style={{marginLeft:'10px'}}>24/7 support</span>
                    </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade">
         <MoneyWork heading="Let your money work for you"/>
        </div>
        <div data-aos="fade">
          <Benefits />
        </div>
        <div className="PopularModels LandingPagePadding" data-aos="fade-up">
          <div style={{ margin: "0 auto", textAlign: "center" }}>
            <span className="LandingPageHeader">Popular models </span>
            <span className="LandingPageDescription">
              Choose from our list of popular models
            </span>
            {/* <div className="BrandBodyDiv" >
              <button className="brandButton">
                Brand
              </button>
              <button style={{backgroundColor:'#fafafa'}} className="brandButton">
                Body type
              </button>
            </div> */}
            <div className="ModelContainer">
              <Grid container spacing={4}>
                {this.state.popularModels.map((model, index) => (
                  <Grid item xs={6} sm={6} md={6} lg={4}>
                    <div className="PopularModel" key={index}>
                      <div>
                        <img src={model.src} alt={model.model} />
                        <br />
                        <span
                          className="LandingPageDescription"
                          style={{ color: "#000000" }}
                        >
                          {model.model}
                        </span>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
            <button
              className="RedirectButton"
              style={{ color: "#002ad1", fontSize: "16px", float: "left" }}
            >
              View all models
            </button>
            <br />
            <div className="ModelContainerB">
            <button
                className="SignUpFormsSubmit"
                style={{
                  width: "264px",
                  height: "56px",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <SearchIcon fontSize="small" />
                Explore investment deals
              </button>
            </div>
          </div>
        </div>
        
        <div className="Boss">
          <div className="BossHeader">
            <span className="LandingPageHeader" style={{ color: "#ffffff" }}>
             Ready to get started
            </span>
            <span style={{fontSize:'24px',display:'block',color:'white',margin:'9px 0'}}>Create your account today and start making your money work for you</span>
            <button
              className="BackButton"
              style={{
                color: "#f58730",
                fontSize: "20px",
              }}
              onClick={() => this.props.history.push("/Signup")}
            >
              Sign up for a free account
              
              <ArrowForwardIcon style={{ marginLeft: "10px" }} />
            </button>
          </div>
          
        </div>
        <div
          className="Stories"
          style={{
            background: `url("./icon/quotes.svg")`,
          }}
        >
          <div className="LandingPagePadding StoriesContainer">
            <div style={{ width: "45%" }}>
              <span className="LandingPageHeader">
                Real Stories from Real Customers
              </span>
            </div>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <span className="LandingPageDescription">
                  Get inspired by these stories.
                </span>
                <div className="StoryA" data-aos="zoom-in-left">
                  <div style={{ display: "flex" }}>
                    <div
                      className="StoryImage"
                      style={{
                        background: `url(${this.state.testimonials[0].src})`,
                      }}
                    ></div>
                    <div>
                      <span className="LandingPageDescription">
                        {this.state.testimonials[0].name}
                      </span>
                      <br />
                      <span
                        className="LandingPageWhiteText"
                        style={{ color: "#969bab" }}
                      >
                        {this.state.testimonials[0].location}
                      </span>
                    </div>
                  </div>
                  <div className="DisplayFlex1" style={{ marginTop: "32px" }}>
                    <div className="StoryQuotes">
                      <img src="/icon/quotes1.svg" alt="" />
                    </div>
                    <div>
                      <span className="LandingPageBlackText">
                        {this.state.testimonials[0].testimony}
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className="StoryB" data-aos="zoom-in-up">
                    <div style={{ display: "flex" }}>
                      <div
                        className="StoryImage"
                        style={{
                          background: `url(${this.state.testimonials[1].src})`,
                        }}
                      ></div>
                      <div>
                        <span className="LandingPageDescription">
                          {this.state.testimonials[1].name}
                        </span>
                        <br />
                        <span
                          className="LandingPageWhiteText"
                          style={{ color: "#969bab" }}
                        >
                          {this.state.testimonials[1].location}
                        </span>
                      </div>
                    </div>
                    <div className="DisplayFlex1" style={{ marginTop: "32px" }}>
                      <div className="StoryQuotes">
                        <img src="/icon/quotes1.svg" alt="" />
                      </div>
                      <div>
                        <span className="LandingPageBlackText">
                          {this.state.testimonials[1].testimony}
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className="StoryC" data-aos="zoom-in-right">
                    <div style={{ display: "flex" }}>
                      <div
                        className="StoryImage"
                        style={{
                          background: `url(${this.state.testimonials[2].src})`,
                        }}
                      ></div>
                      <div>
                        <span className="LandingPageDescription">
                          {this.state.testimonials[2].name}
                        </span>
                        <br />
                        <span
                          className="LandingPageWhiteText"
                          style={{ color: "#969bab" }}
                        >
                          {this.state.testimonials[2].location}
                        </span>
                      </div>
                    </div>
                    <div className="DisplayFlex1" style={{ marginTop: "32px" }}>
                      <div className="StoryQuotes">
                        <img src="/icon/quotes1.svg" alt="" />
                      </div>
                      <div>
                        <span className="LandingPageBlackText">
                          {this.state.testimonials[2].testimony}
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
        <LandingPageFooter />
      </div>
    );
  }
}

export default LandingPage;
