import React, { Component } from "react";
import {
  withStyles,

  Modal,
  Backdrop,
  MuiThemeProvider,
  CircularProgress,
} from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles'
import {
  validateEmail,
  checkArray,
  numberCheck,
} from "../../Utilities/SharedFunctions";
import { withRouter } from "react-router-dom";
import { post_request } from "../../Services/FetchFunctions";
import CustomSnackbar from "../../Components/SharedComponents/CustomSnackbar";

const stylesModal = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    background: "none",
    border: "none",
    borderRadius: "5px",
    boxShadow: "inherit",
  },
  container: {
    minHeight: 450,
    overflowX: "hidden",
  },
});

const themeModal = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(37, 43, 51, 0.5",
        backdropFilter: "blur(2px)",
      },
    },
  },
});

class PhoneVerificationModal extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      completed: 5,
      buffer: 10,
      title: "",
      verificationCode: ["", "", "", "", ""],
      validEmail: false,
      success: false,
      isLoading: false,
      responseStatus: "",
      snackbaropen: false,
      snackbarmsg: "",
      snackbartxt: "",
      uniqueId: "",
    };

    this.handleFormInput = this.handleFormInput.bind(this);

    this.handleVerificationInput = this.handleVerificationInput.bind(this);
  }

  handleFormInput(event) {
    this.setState({ [event.target.id]: event.target.value });
    if (event.target.id === "email") {
      this.setState({ validEmail: validateEmail(event.target.value) });
    }
  }
  handleVerificationInput(event) {
    var index = event.target.id;
    const newArray = Array.from(this.state.verificationCode);
    const nextSibling = document.querySelector(
      `input[name=ssn-${parseFloat(index, 10) + 1}]`
    );

    if (numberCheck(event.target.value)) {
      if (event.target.value.length > 1) {
        for (var i = 0; i < 5; i++) {
          newArray[i] = event.target.value.charAt(i);
        }
        document.querySelector(`input[name=ssn-4]`).focus();
      } else {
        newArray[index] = event.target.value;
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
      this.setState({ verificationCode: newArray });
    } else {
      newArray[index] = "";
      this.setState({ verificationCode: newArray });
    }
  }
  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.handleVerification();
    }
  }
  handleVerification = async () => {
    var verificationCode = this.state.verificationCode
      .toString()
      .replace(/,/g, "");

    this.setState({ isLoading: true });
    //console.log(verificationCode);
    var payload = {
      UniqueID: this.props.uniqueId,
      code: verificationCode,
      type: "2",
    };
    console.log(payload);
    let verifyURL = "pin/validate";
    post_request(verifyURL, payload)
      .then((resp) => {
        //console.log(resp);
        this.setState({
          isLoading: false,
          snackbaropen: true,
          snackbarmsg: "Phone number verified!",
          responseStatus: "success",
        });
        setTimeout(function () {
          window.location.reload();
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
          snackbaropen: true,
          snackbarmsg: "Verification failed",
          responseStatus: "failed",
        });
        console.log(err);
      });
  };
  resendLink() {
    var endpoint = "user/resend-token";
    var payload = {
      type: "PHONE",
    };
    post_request(endpoint, payload)
      .then((res) => {
        this.setState({
          snackbaropen: true,
          responseStatus: "success",
          snackbarmsg: "Kindly check your phone",
          snackbartxt: res.data.Message,
        });
      })
      .catch((err) => {
        this.setState({
          snackbaropen: true,
          snackbarmsg: "Could not send",
          responseStatus: "failed",
        });
      });
  }
  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <CustomSnackbar
          hideAlert={this.snackbarClose}
          showSnack={this.state.snackbaropen}
          hideSnack={this.snackbarClose}
          response={this.state.responseStatus}
          title={this.state.snackbarmsg}
          messagetxt={this.state.snackbartxt}
        />
        <MuiThemeProvider theme={themeModal}>
          <Modal
            className={classes.modal}
            open={this.props.setOpen}
            onClose={() => this.props.handleOpen()}
            closeAfterTransition
            BackdropComponent={Backdrop}
          >
            <div className={classes.paper}>
              <div className="ModalPaper">
                <div className="ModalHeader" style={{ padding: "0px" }}>
                  <div>
                    <button
                      className="BackButton"
                      style={{ float: "right" }}
                      onClick={() => this.props.handleOpen()}
                    >
                      <img src="./icon/close.svg" alt="x" />
                    </button>
                  </div>
                  <div
                    style={{
                      alignItems: "center",
                      display: "inline-block",
                      marginTop: "50px",
                    }}
                  >
                    <span
                      className="MainContentHeader"
                      style={{ fontSize: "20px" }}
                    >
                      Verify phone number{" "}
                    </span>
                    <br />
                    <span className="MainContentSubheader">
                      Enter the code sent to your phone
                    </span>
                  </div>
                </div>
                <div className="SignFormDetails">
                  <div
                    className="DisplayFlexSpace"
                    style={{ marginBottom: "20px" }}
                  >
                    <div className="Verification">
                      <input
                        name="ssn-0"
                        id="0"
                        type="text"
                        autoComplete="off"
                        value={this.state.verificationCode[0]}
                        onChange={this.handleVerificationInput}
                        required
                      />
                    </div>
                    <div className="Verification">
                      <input
                        name="ssn-1"
                        id="1"
                        type="text"
                        maxLength="1"
                        autoComplete="off"
                        value={this.state.verificationCode[1]}
                        onChange={this.handleVerificationInput}
                        required
                      />
                    </div>
                    <div className="Verification">
                      <input
                        name="ssn-2"
                        id="2"
                        type="text"
                        maxLength="1"
                        autoComplete="off"
                        value={this.state.verificationCode[2]}
                        onChange={this.handleVerificationInput}
                        required
                      />
                    </div>
                    <div className="Verification">
                      <input
                        name="ssn-3"
                        id="3"
                        type="text"
                        maxLength="1"
                        autoComplete="off"
                        value={this.state.verificationCode[3]}
                        onChange={this.handleVerificationInput}
                        required
                      />
                    </div>
                    <div className="Verification">
                      <input
                        name="ssn-4"
                        id="4"
                        type="text"
                        maxLength="1"
                        autoComplete="off"
                        value={this.state.verificationCode[4]}
                        onChange={this.handleVerificationInput}
                        onKeyPress={(e) => {
                          this.handleKeyPress(e);
                        }}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="ModalFooter">
                  <button
                    className={
                      checkArray(this.state.verificationCode)
                        ? "SignUpFormsSubmit"
                        : "SignUpFormsSubmitDisabled"
                    }
                    disabled={
                      checkArray(this.state.verificationCode) ? false : true
                    }
                    onClick={() => this.handleVerification()}
                  >
                    {this.state.isLoading ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress
                          style={{ color: "white", marginRight: "10px" }}
                          size={20}
                        />{" "}
                        Verifying . . .
                      </div>
                    ) : (
                      "Verify"
                    )}
                  </button>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "25px",
                    color: "rgba(37, 37, 37, 0.7)",
                  }}
                >
                  <span>Didn’t receive any code? </span>
                  <button
                    className="RedirectButton"
                    onClick={() => this.resendLink()}
                  >
                    Resend code
                  </button>
                  <button
                    className="RedirectButton"
                    style={{ marginBottom: "20px" }}
                    onClick={() => this.props.handleOpen()}
                  >
                    Resend code later
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withRouter(withStyles(stylesModal)(PhoneVerificationModal));
