import React, { Component } from "react";
import {

  Select,
  MuiThemeProvider,
  MenuItem,
} from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles'
import { numberCheck } from "../../Utilities/SharedFunctions";
import { CircularProgress } from "@material-ui/core";
//import Successful from "./Successful";
import { post_request } from "../../Services/FetchFunctions";
import CustomSnackbar from "../SharedComponents/CustomSnackbar";
import ErrorIcon from "@material-ui/icons/Error";

const MenuProps = {
  style: {
    marginTop: "15px",
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

const customTopUp = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiSelect: {
      root: {
        fontFamily: "inherit",
      },
      select: {
        color: "rgba(37, 37, 37, 0.7)",
        "&:focus": {
          backgroundColor: "none",
        },
      },
      icon: {
        position: "absolute",
      },
      nativeInput: {
        width: "auto",
        display: "none !important",
      },
    },
    MuiMenuItem: {
      root: {
        color: "rgba(37, 37, 37, 0.7)",
        fontFamily: "inherit",
        fontSize: "14px",
        height: "40px",
        width: "100%",
        "&:hover": {
          backgroundColor: "#f4f7ff !important",
        },
        "&$selected": {
          backgroundColor: "#f4f7ff",
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: "0px",
        paddingBottom: "0px",
      },
    },
    MuiInput: {
      root: {
        position: "inherit",
        height: "40px",
      },
      underline: {
        "&&&:before": {
          borderBottom: "none",
        },
        "&&:after": {
          borderBottom: "none",
        },
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: "inherit",
        fontSize: "14px",
        marginTop: "10px",
        marginBottom: "20px",
        height: "40px",
        border: "1px solid rgba(37, 43, 51, 0.15)",
        borderRadius: "4px",
        "&:hover": {
          border: "1px solid #f58730",
          boxShadow: "0 7px 9px -3px rgba(70, 70, 70, 0.06)",
        },
        "&:focus": {
          border: "1px solid #f58730",
          boxShadow: "0 7px 9px -3px rgba(70, 70, 70, 0.06)",
        },
      },
      input: {
        padding: "0px",
        border: "none !important",
        boxShadow: "none !important",
        margin: "0px !important",
      },
    },
  },
});

class TopUpMpesa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 0,
      sourceAccount: "",
      phoneNumber: "",
      amount: "",
      selectedCurrency: "KES",
      selectedCode: "254",
      availableCurrencies: ["KES", "USD", "GBP", "EUR"],
      countryCode: ["254", "255"],
      isLoading: false,
      responseStatus: "",
      snackbaropen: false,
      snackbarmsg: "",
      snackbartxt: "",
      uniqueRef: "",
      paymentResponse: "",
      paymentResponseStatus: "",
      paymentRef: "",
      clientPhone: ""
    };

    this.handleFormInputNumber = this.handleFormInputNumber.bind(this);
    this.handleFormInput = this.handleFormInput.bind(this);
    this.handleView = this.handleView.bind(this);
    this.checkStatus = this.checkStatus.bind(this);
  }

  componentDidMount() {

    var data = this.props.clientData;
    this.setState({ phoneNumber: data });
  }
  handleView(view) {
    this.setState({ view: view });
  }

  handleFormInputNumber(event) {
    if (event.target.value.length > 0) {
      if (numberCheck(event.target.value)) {
        this.setState({ [event.target.id]: event.target.value });
      }
    } else {
      this.setState({ [event.target.id]: "" });
    }
  }

  handleFormInput(event) {
    if (event.target.id) {
      this.setState({ [event.target.id]: event.target.value });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  handleSubmit() {
    this.setState({ isLoading: true });
    var payload = {
      Type: "MPESA-CHECKOUT",
      AccountNumber: this.state.phoneNumber,
      Amount: this.state.amount,
      Currency: this.state.selectedCurrency,
    };
    var url = "client/account/top-up-account";
    post_request(url, payload)
      .then((resp) => {
        console.log(resp);
        this.setState({
          isLoading: false,
          //snackbaropen: true,
          //snackbarmsg: "Payment request sent!",
          responseStatus: "success",
          snackbartxt: resp.data.Message,
          uniqueRef: resp.data.uniqueRef,
        });
        var uniqueRef = resp.data.uniqueRef;
        this.checkStatus(uniqueRef);
      })
      .catch((err) => {
        console.log(err.response);
        this.setState({
          isLoading: false,
          // snackbaropen: true,
          snackbarmsg: "Payment request failed!",
          responseStatus: "failed",
          snackbartxt: err.response.data.Message,
        });
        var uniqueRef = err.response.data.uniqueRef;
        this.checkStatus(uniqueRef);
      });
  }
  checkStatus(ref) {
    const statusURL = "client/account/mpesa-check-out";
    var payload = {
      UniqueRef: ref,
    };
    post_request(statusURL, payload)
      .then((resp) => {
        console.log(resp);
        this.setState({
          paymetRef: resp.data.MpesaRef,
          paymentResponseStatus: resp.data.Status,
          paymentResponse: resp.data.Message,
          view: 2,
        });
        setTimeout(function () {
          window.location.reload();
        }, 4500);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }
  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  render() {
    // console.log(this.state.phoneNumber)
    return (
      <div>
        <CustomSnackbar
          hideAlert={this.snackbarClose}
          showSnack={this.state.snackbaropen}
          hideSnack={this.snackbarClose}
          response={this.state.responseStatus}
          title={this.state.snackbarmsg}
          messagetxt={this.state.snackbartxt}
        />
        <div style={{ marginTop: '100px', paddingLeft: '20%' }} className="ButtonPadding">
          <button
            className="BackButton"
            onClick={() => {
              this.state.view === 0
                ? this.props.handleView(0)
                : this.handleView(0);
            }}
          >
            <img src="/icon/back.svg" alt="back" />
            <span>Back</span>
          </button>
        </div>
        {this.state.view === 0 && (
          <div className="ContentSpace">
            <div className="PersonalInfo PaymentsForm">
              <div
                className="ContentHeader"
                style={{ textAlign: "center", marginBottom: "35px" }}
              >
                <span
                  className="MainContentHeader Bold"
                  style={{ display: "block", marginBottom: "10px" }}
                >
                  Top up with Mpesa
                </span>
                <span className="MainContentSubheader">
                  Enter the amount you’d like to top-up and fill up the payment
                  details below{" "}
                </span>
              </div>
              <MuiThemeProvider theme={customTopUp}>
                <div className="ProfileForm">
                  <label style={{ display: "block" }}>
                    How much do you want to top up?
                  </label>

                  <label style={{ marginTop: "10px" }}>Amount</label>
                  <div className="SignUpFormsTwoInOne">
                    <div className="SignUpFormsTwoInOneSmall">
                      <Select
                        className="PaymentSelect"
                        style={{
                          fontFamily: "inherit",
                          fontSize: "14px",
                          marginTop: "0px",
                        }}
                        name="selectedCurrency"
                        value={this.state.selectedCurrency}
                        onChange={(event) => this.handleFormInput(event)}
                        disableUnderline={true}
                        MenuProps={MenuProps}
                      >
                        {this.state.availableCurrencies.map((code, index) => (
                          <MenuItem value={code} key={index}>
                            {code}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <input
                      id="amount"
                      type="text"
                      autoComplete="off"
                      maxLength="9"
                      value={this.state.amount}
                      onChange={this.handleFormInputNumber}
                      required
                    />
                  </div>
                  <div>
                    <label style={{ display: "block" }}>
                      Enter your MPESA phone number
                    </label>
                    <span
                      style={{ display: "block", marginBottom: "10px" }}
                      className="MainContentSubheader"
                    >
                      You’ll will receive a notification on your phone. Kindly
                      enter your PIN when requested{" "}
                    </span>
                  </div>
                  <label>Phone Number</label>
                  <input
                    id="phoneNumber"
                    type="text"
                    maxLength="14"
                    value={this.state.phoneNumber}
                    onChange={this.handleFormInputNumber}
                    required
                  />

                  <button
                    className={
                      this.state.phoneNumber !== "" &&
                        this.state.amount !== "" &&
                        this.state.selectedCurrency !== ""
                        ? "SignUpFormsSubmit"
                        : "SignUpFormsSubmitDisabled"
                    }
                    disabled={
                      this.state.phoneNumber !== "" &&
                        this.state.amount !== "" &&
                        this.state.selectedCurrency !== ""
                        ? false
                        : true
                    }
                    onClick={() => this.setState({ view: 1 })}
                  >
                    {this.state.isLoading ? (
                      <CircularProgress style={{ color: "white" }} size={20} />
                    ) : (
                      "Make payment"
                    )}
                  </button>
                </div>
              </MuiThemeProvider>
            </div>
          </div>
        )}
        {this.state.view === 1 && (
          <div className="ContentSpace">
            <div className="PersonalInfo PaymentsForm">
              <div
                className="ContentHeader"
                style={{ textAlign: "center", marginBottom: "35px" }}
              >
                <span
                  className="MainContentHeader Bold"
                  style={{ display: "block", marginBottom: "10px" }}
                >
                  Confirm transaction details
                </span>
                <span className="MainContentSubheader">
                  Please confirm the transaction details below before making
                  payment.{" "}
                </span>
                <span
                  className="MainContentHeader Bold"
                  style={{
                    display: "block",
                    marginTop: "20px",
                    marginBottom: "15px",
                  }}
                >
                  You are topping up your investment account
                </span>

                <div style={{ height: "235px" }} className="SendMoneyBalance">
                  <span className="MainContentSubheader">
                    Amount to be debited from your MPESA
                  </span>

                  <span
                    style={{ color: "#252b33", marginBottom: "20px" }}
                    className="BlueBold"
                  >
                    KES {this.state.amount}
                  </span>
                  <br />
                  <span className="MainContentSubheader">
                    Amount received in your invesment account
                  </span>

                  <span className="BlueBold">KES {this.state.amount}</span>
                </div>
                <button
                  className="SignUpFormsSubmit"
                  onClick={() => this.handleSubmit()}
                >
                  {this.state.isLoading ? (
                    <CircularProgress style={{ color: "white" }} size={20} />
                  ) : (
                    "Confirm payment"
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
        {this.state.view === 2 && (
          <div className="ContentSpace">
            <div className="PersonalInfo PaymentsForm">
              <div className="PaymentResponse">
                {this.state.paymentResponseStatus === "00" ? (
                  <img src="/icon/successful.svg" alt="success" />

                ) : (
                  <ErrorIcon
                    fontSize="large"
                    style={{ marginRight: "5px", fontSize: '4rem', color: "#F64417" }}
                  />
                )}

                {/* {this.state.paymentResponseStatus === "PENDING" ? (
                  <ErrorIcon
                    fontSize="large"
                    style={{ marginRight: "5px", color: "#b55407" }}
                  />
                ) : null} */}
              </div>
              <div
                className="ContentHeader"
                style={{ textAlign: "center", marginBottom: "35px" }}
              >
                <span
                  className="MainContentHeader Bold"
                  style={{
                    display: "block",
                    marginBottom: "10px",
                    textTransform: "capitalize",
                  }}
                >
                  Transaction {this.state.paymentResponseStatus === "00" ? (
                    <span> successful </span>
                  ) : (
                    <span> failed </span>
                  )}
                </span>
                <span className="MainContentSubheader">
                  {this.state.paymentResponse}
                </span>
              </div>
              <div>
                {this.state.paymentResponseStatus !== "00" && (
                  <button className="SignUpFormsSubmit" onClick={() => this.handleView(0)}>
                    Try again
                  </button>
                )}

              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default TopUpMpesa;
