import React, { Component } from "react";
import TopBar from "../../Components/SharedComponents/TopBar";
import Menu from "../../Components/SharedComponents/Menu";
import Search from "../../Components/SharedComponents/Search";
import Filter from "../../Components/SharedComponents/Filter";
import BookingHistoryTable from "../../Components/BookingHistory/BookingHistoryTable";
import {getRequest} from "../../Services/FetchFunctions";
import NoData from "../../Components/SharedComponents/NoData";

class BookingHistory extends Component {
  constructor() {
    super();
    this.state = {
      bookings: [],
      bookingHistory: [],
      systemFee: 500,
      searchItem: "",
      selected: {},
      startDate: new Date(),
      endDate: new Date(),
      name: "",
      invAccount: [],
      incomeAccount: []
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
  }

  componentDidMount() {
   this.getBookings();
   this.getAccount()
  }
  getBookings(){
    const url = 'client/booking/history';
    getRequest(url).then((resp)=> {
      //console.log(resp);
      this.setState({bookingHistory: resp.data.List})
    }).catch((err)=> {
      console.log(err);
    })
  }
  getAccount(){
    const url = "client/account/get-accounts";
    getRequest(url)
      .then((resp) => {
        let accounts = resp.data.List;
        let filteredAccount = accounts.filter(
          (account) => account.AccountType === "INVESTMENT"
        );
        let income = accounts.filter(
          (account) => account.AccountType === "INCOME"
        );
        console.log(income)
        this.setState({
          incomeAccount: income, invAccount: filteredAccount
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleSearch(searchItem) {
    this.setState({ searchItem: searchItem });
  }

  handleFilter(startDate, endDate) {
    this.setState({ startDate: startDate, endDate: endDate });
  }
  render() {
    var bookingHistory = this.state.bookingHistory.filter(entry =>
      Object.values(entry).some(
        val =>
          typeof val === "string" &&
          val.toLowerCase().includes(this.state.searchItem.toLowerCase())
      )
    );
  
    let balance='0.00';
    let CurrencyFormat = require("react-currency-format");
    
    let income = this.state.incomeAccount[0];
   
    if (income !== undefined) {
      //curr = account.AccountCurrency;
      balance = income.AccountBalance;
   }
  // console.log(this.state.incomeAccount)
    return (
      <div>
       
          <div className="ContainerApp">
            <TopBar />
            <div className="MainBody">
              <div className="Menu">
                <Menu />
              </div>
              <div className="ContentFull" style={{ background: "#ffffff" }}>
                <div style={{display:'flex', justifyContent:'space-between'}} className="ContentHeaderBlue">
                  <div>
                  <span
                    className="MainContentHeader Bold"
                    style={{ color: "#ffffff" }}
                  >
                    Booking history
                  </span>
                  <br />
                  <span
                    className="MainContentSubheader"
                    style={{ color: "#F5F9FE" }}
                  >
                    Showing all records of recent bookings. Click to view more
                    details
                  </span>
                  </div>
                  
                  <div>
            <span
              className="WhiteText"
              //style={{ color: " rgba(245, 249, 254, 0.63)" }}
            >
            Income Account Balance
            </span>
           
            <span style={{ marginTop: "10px",display:'block',fontSize:'20px' }} className="WhiteText" >
            KES <CurrencyFormat value={parseFloat(balance).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}/>
            </span>
          </div>
                </div>
                <div
                  className="ContentSpace"
                  style={{ padding: "30px", marginTop: "10px" }}
                >
                  <div className="DisplayFlexB">
                    <div style={{ marginRight: "15px", marginBottom: "20px" }}>
                      <Filter handleFilter={this.handleFilter} />
                    </div>
                    <Search
                      searchItem={this.state.searchItem}
                      placeholder={"Search by vehicle name or number plate"}
                      handleSearch={this.handleSearch}
                    />
                  </div>
                  {this.state.bookingHistory.length === 0 ? (
                  <NoData title={"   COMING SOON ..."}
                  />
                ) : (
                  <div>
                    <BookingHistoryTable
                      bookingHistory={bookingHistory}
                      systemFee={this.state.systemFee}
                    />
                  </div>
                )}
                </div>
              </div>
            </div>
          </div>
      
      </div>
    );
  }
}

export default BookingHistory;
